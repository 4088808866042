<h2 class="no-margin modal-title">Client details</h2>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form class="form-container padding-xs" [formGroup]="form">
    <div class="form-wrapper gap-sm form-input--lg">
      <mat-label>Client name</mat-label>
      <mat-form-field>
        <input placeholder="Client name" matInput formControlName="name" />
        <mat-error *ngIf="form.get('name')?.hasError('required')"> Client name is required </mat-error>
      </mat-form-field>
    </div>

    <div class="form-wrapper gap-sm form-input--lg">
      <mat-label>Client Sector</mat-label>
      <mat-form-field>
        <input matInput placeholder="Client sector" formControlName="sector" />
        <mat-error *ngIf="form.get('sector')?.hasError('required')"> Client sector is required </mat-error>
      </mat-form-field>
    </div>

    <div class="form-wrapper gap-sm">
      <mat-label>Client description</mat-label>
      <mat-form-field>
        <textarea
          class="no-resize"
          placeholder="Enter any additional information about the client that may be relevant.
        E.g.
        - What is the client statement?
        - What’s the client vision?
        - How did the company start?
        - What is their core offering?
        - What sets the company apart from their competitors?
        - Values & Philosophy"
          matInput
          formControlName="description"
          rows="10"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="padding-xs">
  <button mat-button class="btn btn--secondary" (click)="closeModal()">Cancel</button>
  <button mat-button class="btn btn--primary" (click)="saveClientDetails()" [disabled]="form.invalid">
    Save details
  </button>
</mat-dialog-actions>
