import { CompanyFeaturesInterface } from './company-features.interface';

export interface NavbarItemInterface {
  /**
   * Name of the item
   */

  name: string;

  /**
   * Icon of the item
   */
  icon?: string;

  /**
   * Route of the item
   */
  path: string;

  /**
   * Boolean if needs admin
   */
  needsAdmin: boolean;

  /**
   * Permission required
   */
  permissionRequired?: CompanyFeaturesInterface;

  /**
   * Whether the menu item is active
   */
  active?: boolean;

  /**
   * The partial URL should match in order to highlight the icon
   * I.e. ['etl-settings', 'etl-settings-user']
   */
  match?: string[];
}

export const navbarItems: NavbarItemInterface[] = [
  {
    name: 'Overview',
    path: '/overview',
    match: ['overview'],
    permissionRequired: CompanyFeaturesInterface.GrantGenerator,
    icon: 'home-outline',
    active: false,
    needsAdmin: false
  },
  {
    name: 'Applications',
    path: '/applications',
    match: ['applications'],
    permissionRequired: CompanyFeaturesInterface.GrantGenerator,
    icon: 'flash-outline',
    active: false,
    needsAdmin: false
  },
  {
    name: 'Clients',
    icon: 'briefcase-outline',
    path: '/clients',
    match: ['clients'],
    permissionRequired: CompanyFeaturesInterface.GrantGenerator,
    active: false,
    needsAdmin: false
  },
  {
    name: 'Chat',
    icon: 'chatbubble-outline',
    path: '/chat',
    match: ['chat'],
    permissionRequired: CompanyFeaturesInterface.GeneralChat,
    active: false,
    needsAdmin: false
  },
  {
    name: 'Users',
    icon: 'people-outline',
    path: '/users',
    match: ['users'],
    needsAdmin: true
  }
];
