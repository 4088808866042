import { Component, Input, ViewChild } from '@angular/core';
import { ConfirmModalInterface, ConfirmModalType } from '../../../interfaces/confirm-modal.interface';
import {
  DisplayedColumnsObject,
  DynamicHttpTableComponent,
  DynamicHttpTableModule
} from '@components/ng-dynamic-http-table';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { CustomSnackbarService } from '../../../services/custom-snackbar.service';
import { Document } from '../../../models/document';
import { DocumentService } from '../../../services/document.service';
import { MatDialog } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import prettyBytes from 'pretty-bytes';

@Component({
  selector: 'app-application-history',
  templateUrl: './application-history.component.html',
  styleUrls: ['./application-history.component.scss'],
  standalone: true,
  imports: [NgIf, DynamicHttpTableModule]
})
export class ApplicationHistoryComponent {
  @Input() subtitle?: string;

  @ViewChild('applicationHistoryTable', { static: false }) applicationHistoryTable?: DynamicHttpTableComponent;

  columns: DisplayedColumnsObject;

  constructor(
    public documentService: DocumentService,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: CustomSnackbarService
  ) {
    this.columns = {
      filename: {
        label: 'File name',
        type: 'text',
        template:
          '<div class="row gap-sm vertical-center">' +
          '<ion-icon class="table-icon" name="document-text-outline"></ion-icon>' +
          '${filename}' +
          '</div>'
      },
      createdAt: {
        label: 'Uploaded at',
        filter: (date: string) => {
          return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          });
        }
      },
      size: {
        label: 'File size',
        type: 'text',
        filter: this.pretty
      },
      menu: {
        label: '',
        type: 'menu-button',
        class: 'fa-solid fa-ellipsis-vertical',
        elements: [
          {
            label: 'Download',
            class: 'fa-solid fa-download',
            itemClass: 'warn-color-600',
            method: (doc: Document): void => this.downloadDocument(doc)
          },
          {
            label: 'Remove',
            class: 'fa-solid fa-trash warn-color-600',
            itemClass: 'warn-color-600',
            method: (doc: Document): void => this.deleteDocument(doc)
          }
        ]
      }
    };
  }

  pretty = (size: number): string => {
    if (size) {
      return prettyBytes(size);
    } else {
      return '';
    }
  };

  deleteDocument(document: Document): void {
    const dialogData: ConfirmModalInterface = {
      title: 'Delete past application',
      message: 'Are you sure you want to delete this section? This document won’t be used in future generations',
      type: ConfirmModalType.DELETE
    };

    const dialogConfig = {
      data: dialogData,
      minWidth: '400px'
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.documentService.deleteDocument(document.id).subscribe(
          () => {
            this.snackbar.present('Applications successfully deleted');
            this.applicationHistoryTable?.getResults();
          },
          () => {
            this.snackbar.present('Error when deleting the application');
          }
        );
      }
    });
  }

  downloadDocument(doc: Document): void {
    if (doc) {
      window.open(this.documentService.downloadDocument(doc.id), '_blank');
    }
  }
}
