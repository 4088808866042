import { NavbarItemInterface, navbarItems } from '../../interfaces/navbar.interface';
import { Router, RouterOutlet } from '@angular/router';
import { Component } from '@angular/core';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [NavbarComponent, RouterOutlet]
})
export class MainComponent {
  navbarItems: NavbarItemInterface[] = navbarItems;

  constructor(private router: Router) {}

  routeActivated(_: Component): void {
    this.activatedMenuItem();
  }

  activatedMenuItem(): void {
    const originalUrl = this.router.url;
    let url = '';
    if (originalUrl[0] === '/') {
      // Remove heading slash
      url = originalUrl.substr(1);
    }

    const firstUrlComponent = url.split(/\/|\?/)[0];

    [...this.navbarItems].forEach((menuItem) => {
      menuItem.active = menuItem.match?.indexOf(firstUrlComponent) !== -1;
    });
  }
}
