import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

export class Document {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id?: number;

  /**
   * Document title
   */
  @autoserializeAs(() => String) filename?: string;

  /**
   * Document URL
   */
  @autoserializeAs(() => String) url?: string;

  /**
   *
   * Document size in B
   */
  @autoserializeAs(() => Number) size?: number;

  /**
   * Created at
   */
  @autoserializeAs(() => Date, 'created_at') createdAt?: Date;

  /**
   *
   * ID of the offer that owns the document
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @autoserializeAs(() => Number, 'offer_id') offerId?: number;

  constructor(id: number, filename: string, url: string, size: number, offerId: number, createdAt: Date) {
    this.id = id;
    this.filename = filename;
    this.url = url;
    this.size = size;
    this.offerId = offerId;
    this.createdAt = createdAt;
  }
}

export class DocumentList {
  @autoserializeAsArray(() => Document) items: Document[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: Document[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}
