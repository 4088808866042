import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmModalInterface, ConfirmModalType } from '../../interfaces/confirm-modal.interface';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgFor, NgIf } from '@angular/common';
import { Section, SectionInput } from '../../models/section';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Document } from '../../models/document';
import { DocumentService } from '../../services/document.service';
import { EditSectionComponent } from '../edit-section/edit-section.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SectionItemComponent } from './section-item/section-item.component';
import { SectionService } from '../../services/section.service';
import { TinymceEditorComponent } from '../edit-section/tinymce-editor/tinymce-editor.component';
import { UserInputService } from '../../services/user-input.service';

@Component({
  selector: 'app-sections-cdk-drop-list',
  templateUrl: './sections-cdk-drop-list.component.html',
  styleUrls: ['./sections-cdk-drop-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinnerModule,
    CdkDropList,
    NgFor,
    EditSectionComponent,
    SectionItemComponent,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SectionsCdkDropListComponent implements OnInit, AfterViewInit {
  editIndex: number | null = null;
  editMode = false;
  sectionList: Section[] = [];
  totalSections = 0;
  documentList: Document[] = [];
  totalDocuments = 0;
  addingSection = false;
  userInput = '';
  loading = false;

  @Input() isTemplate = false;
  @Input() offerId?: number;
  @Input() edit = false;

  @Output() disableButtons = new EventEmitter<boolean>();

  @ViewChild(TinymceEditorComponent) tinymceEditorComponent!: TinymceEditorComponent;

  addSectionForm: FormGroup;

  constructor(
    private sectionService: SectionService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    private builder: NonNullableFormBuilder,
    private userInputService: UserInputService
  ) {
    this.addSectionForm = this.builder.group({
      title: this.builder.control('', [Validators.required])
    });
  }

  private getSections(offerId?: number): void {
    this.sectionService.getSectionsByOffer(offerId).subscribe((res) => {
      this.sectionList = res.items;
      this.totalSections = res.total;
    });
  }

  private getDocuments(): void {
    this.documentService.getDocumentsByOfferId(this.offerId).subscribe((res) => {
      this.documentList = res.items;
      this.totalDocuments = res.total;
    });
  }

  ngOnInit(): void {
    if (this.offerId != null && !this.edit) {
      this.loading = true; // fot the loading spinner to appear
      this.disableButtons.emit(true); // disable buttons while loading the sections

      this.userInputService.data$.subscribe((data) => {
        // get the user input data
        this.userInput = data;
      });

      const offerId = this.offerId;

      // Load the sections iteratively, using the user input and previous sections as inputs for the next ones
      this.sectionService
        .listContent(offerId, 'description', this.userInput)
        .subscribe((descriptionSection: Section) => {
          this.addGeneratedSection(descriptionSection.title, descriptionSection.body, 0);
        });

      this.sectionService.listContent(offerId, 'challenges', this.userInput).subscribe((challengesSection: Section) => {
        this.addGeneratedSection(challengesSection.title, challengesSection.body, 1);

        this.sectionService
          .listContent(offerId, 'solutions', challengesSection.body)
          .subscribe((solutionsSection: Section) => {
            this.addGeneratedSection(solutionsSection.title, solutionsSection.body, 2);

            this.sectionService
              .listContent(offerId, 'novelty', solutionsSection.body)
              .subscribe((noveltySection: Section) => {
                this.addGeneratedSection(noveltySection.title, noveltySection.body, 3);
                this.loading = false;
                this.disableButtons.emit(false);
              });
          });
      });
    }
  }

  ngAfterViewInit(): void {
    this.getSections(this.offerId);
  }

  /**
   * Method to add in the db the generated sections
   * @param title
   * @param body
   * @param orderSection
   */
  addGeneratedSection(title: string, body: string, orderSection: number): void {
    const newSection: SectionInput = {
      title: title,
      body: `<h1>${title}</h1><p>${body}</p>`,
      order: orderSection,
      offerId: this.offerId
    };

    this.sectionService.createSection(newSection).subscribe((res) => {
      this.sectionList.push(res);
      this.sectionList.sort((a, b) => a.order - b.order);
    });
  }

  drop(event: CdkDragDrop<string[]>, sections: Section[]): void {
    sections[event.previousIndex].order = event.currentIndex;
    this.sectionService.update(sections[event.previousIndex]).subscribe();
    if (event.previousIndex < event.currentIndex) {
      sections.slice(event.previousIndex + 1, event.currentIndex + 1).forEach((section) => {
        section.order--;
        this.sectionService.update(section).subscribe();
      });
    } else {
      sections.slice(event.currentIndex, event.previousIndex).forEach((section) => {
        section.order++;
        this.sectionService.update(section).subscribe();
      });
    }
    moveItemInArray(this.sectionList, event.previousIndex, event.currentIndex);
  }

  deleteSection(id: number): void {
    const dialogData: ConfirmModalInterface = {
      title: 'Delete section',
      message: 'Are you sure you want to delete this section?',
      type: ConfirmModalType.DELETE
    };

    const dialogConfig: MatDialogConfig = {
      width: '400px',
      data: dialogData
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let indexDeleted = -1;
        this.sectionList.forEach((section, index) => {
          if (section.id == id) {
            indexDeleted = index;
            this.sectionList.splice(index, 1);
            this.sectionService.delete(id).subscribe();
          }
        });

        if (indexDeleted != -1) {
          this.sectionList.slice(indexDeleted, this.sectionList.length).forEach((section) => {
            section.order--;
            this.sectionService.update(section).subscribe();
          });
        }
      }
    });
  }

  changeEditMode(order: number): void {
    this.editMode = !this.editMode;
    this.editIndex = order;
  }

  saveChanges(section: Section): void {
    this.editMode = !this.editMode;
    this.sectionService.update(section).subscribe();
  }

  addSection(): void {
    const sectionTitle = this.addSectionForm.controls.title.value;

    const newSection: SectionInput = {
      title: sectionTitle,
      body: `<h1>${sectionTitle}</h1>`,
      order: this.sectionList.length,
      offerId: this.offerId
    };

    this.sectionService.createSection(newSection).subscribe((res) => {
      this.sectionList.push(res);
    });

    this.addSectionForm.controls.title.setValue('');
    this.addingSection = !this.addingSection;
  }

  changeAddingSection(): void {
    this.addingSection = !this.addingSection;
    this.addSectionForm.controls.title.setValue('');
  }

  deleteDocument(document: Document): void {
    this.documentList.forEach((item, index) => {
      if (item.id == document.id) {
        this.documentList.splice(index, 1);
        this.documentService.deleteDocument(document.id).subscribe();
      }
    });
  }
}
