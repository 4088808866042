<form class="form-wrapper fill-vertical-horizontal" *ngIf="!loading">
  <section class="column gap-sm">
    <mat-label> Application input </mat-label>
    <mat-form-field class="fill gap-sm horizontal-center">
      <textarea
        class="description-input form-textarea"
        matInput
        [formControl]="description"
        placeholder="Outline the key aspects of your application, including the technical challenges faced and the proposed technical solutions. "
      >
      </textarea>
      <mat-hint class="subtitle">
        The information here will be used to assess and generate your WBSO aplication
      </mat-hint>
    </mat-form-field>
  </section>
</form>
<div class="fill-vertical-horizontal column center form-textarea" *ngIf="loading">
  <mat-spinner color="accent" [diameter]="50"></mat-spinner>
  <span class="subtitle">We're generating your WBSO application...</span>
</div>

<div class="row vertical-center fill horizontal-end gap-sm">
  <button mat-button class="btn btn--back" (click)="back()" [disabled]="loading">
    <ion-icon name="chevron-back-outline"></ion-icon>
    Back
  </button>
  <button mat-button class="btn btn--primary row gap" (click)="endStep()" [disabled]="loading">
    Generate application
    <ion-icon name="flash-outline"></ion-icon>
  </button>
</div>
