import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { Section, SectionInput, SectionList } from '../models/section';
import { ApiService } from './api.service';
import { GenerateUserInput } from '../models/generate-user-input';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SectionService {
  /**
   * API path
   */
  path = '/section';

  /**
   * Constructor
   * @param http - HTTP client service
   * @param apiService - API service
   */
  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  /**
   * Get all sections
   */
  list(): Observable<SectionList> {
    return this.http.get<IJsonObject>(`${this.path}`).pipe(map((sections) => Deserialize(sections, () => SectionList)));
  }

  /**
   * Update a section given its ID
   */
  update(newSection: Section): Observable<any> {
    return this.http.put<IJsonObject>(
      `${this.path}/${newSection.id}`,
      Serialize(newSection, () => Section)
    );
  }

  /**
   * Delete a section given its ID
   */
  delete(sectionId: number): Observable<void> {
    return this.http.delete<void>(`${this.path}/${sectionId}`);
  }

  /**
   * Get all sections of an offer
   */
  getSectionsByOffer(offerId?: number): Observable<SectionList> {
    return this.http
      .get<IJsonObject>(`${this.path}/offer/${offerId}`)
      .pipe(map((sections) => Deserialize(sections, () => SectionList)));
  }

  /**
   * Create a new section
   */
  createSection(newSection: SectionInput): Observable<Section> {
    return this.http
      .post<IJsonObject>(
        `${this.path}`,
        Serialize(newSection, () => SectionInput)
      )
      .pipe(map((section) => Deserialize(section, () => Section)));
  }

  listContent(offerId: number, contentType: string, userInput: string): Observable<Section> {
    const requestBody = { userInput: userInput };

    return this.http
      .post<IJsonObject>(
        `${this.path}/${offerId}/generate/${contentType}`,
        Serialize(requestBody, () => GenerateUserInput)
      )
      .pipe(map((offerList) => Deserialize(offerList, () => Section)));
  }
}
