<div class="center column margin-bottom" *ngIf="loading">
  <mat-spinner color="accent" [diameter]="50"></mat-spinner>
  <span class="subtitle">We're generating your WBSO application...</span>
</div>
<div *ngIf="sectionList.length" class="section-list" cdkDropList (cdkDropListDropped)="drop($event, sectionList)">
  <div class="section-box column gap offer" *ngFor="let section of sectionList; let i = index">
    <div class="fill-vertical-horizontal" *ngIf="editMode && editIndex === i; else rOnlyMode">
      <app-edit-section
        [section]="section"
        (saveSection)="saveChanges($event)"
        (editMode)="changeEditMode($event)"
      ></app-edit-section>
    </div>
    <ng-template #rOnlyMode>
      <app-section-item
        [section]="section"
        (editModeEmitter)="changeEditMode($event)"
        (deleteSectionEmitter)="deleteSection($event)"
      ></app-section-item>
    </ng-template>
  </div>
</div>
<div class="new-section">
  <section *ngIf="!addingSection" class="column gap-sm">
    <span>Add a new section</span>
    <button (click)="changeAddingSection()" type="submit" mat-button class="btn btn--primary" [disabled]="loading">
      <ion-icon name="add-outline"></ion-icon>
      Add section
    </button>
  </section>
  <form class="form-container" *ngIf="addingSection" [formGroup]="addSectionForm" (ngSubmit)="addSection()">
    <mat-label class="horizontal-start fill">Section title</mat-label>
    <mat-form-field>
      <input matInput type="text" formControlName="title" placeholder="New title" />
    </mat-form-field>
    <div class="row gap-sm">
      <button type="button" (click)="changeAddingSection()" mat-button class="btn btn-accent">Discard</button>
      <button mat-button class="btn btn--primary">
        <div class="vertical-center gap-sm">
          Add
          <ion-icon class="icon-md" name="add-outline"></ion-icon>
        </div>
      </button>
    </div>
  </form>
</div>
