<section class="horizontal-space-between vertical-center row">
  <section class="column gap-sm">
    <h2 class="no-margin">Generated applications</h2>
    <span *ngIf="subtitle" class="description">{{ subtitle }}</span>
  </section>

  <a *ngIf="link" routerLink="/applications" class="all-link">
    <span>View all applications</span>
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </a>
</section>

<ng-dynamic-http-table
  #applicationsTable
  [displayedColumnsObject]="columns"
  [crudClass]="tenderOffersService"
  [crudMethodName]="'list'"
  [showSearch]="false"
  [mode]="'table'"
  [pageSize]="pageSize"
  defaultSortingField="projectNumber"
  defaultSortingOrder="desc"
  [useAPS]="true"
  (actionPropagateHappened)="handleActionHappened($event)"
>
</ng-dynamic-http-table>
