import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '../../../utils/constants';
import { ActivateAccountInterface } from '../../../interfaces/user.interface';
import { CustomSnackbarService } from '../../../services/custom-snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { matchValidator } from '../../../utils/validators';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatButtonModule]
})
export class ActivateAccountComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly PASSWORD_MIN_LENGTH = PASSWORD_MIN_LENGTH;

  form: FormGroup;

  token?: string;

  tokenValid = false;

  constructor(
    private userService: UserService,
    private snackbarService: CustomSnackbarService,
    private router: Router
  ) {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      lastName: new FormControl(''),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(PASSWORD_MIN_LENGTH),
        Validators.pattern(PASSWORD_REGEX),
        matchValidator('confirmPassword', true)
      ]),
      confirmPassword: new FormControl('', [Validators.required, matchValidator('password')])
    });
  }

  ngOnInit(): void {
    const splitUrl = this.router.url.split('/');

    this.token = splitUrl[splitUrl.length - 1];
    this.userService.validateResetPasswordToken(this.token).subscribe(() => {
      this.tokenValid = true;
    });
  }

  onSubmit(): void {
    const data: ActivateAccountInterface = {
      name: this.form.value.name,
      lastName: this.form.value.lastName,
      password: this.form.value.password
    };

    if (data.password === this.form.value.confirmPassword) {
      this.userService.activateAccount(data, this.token).subscribe(() => {
        this.snackbarService.present('Account activated');
        this.router.navigate(['/login']);
      });
    }
  }
}
