<input type="file" #fileInput (change)="handleFileBrowser($event)" class="hidden" />
<div
  (dragover)="preventDefault($event)"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="handleDrop($event)"
  class="drag-drop-area"
  id="drag-drop"
  (click)="fileInput.click()"
>
  <ion-icon class="circle-icon" name="cloud-upload-outline"></ion-icon>
  <span class="description"><a class="link">Click here to upload</a> or drag and drop files here</span>
  <span class="description">{{ formats }}</span>
  <span class="description" *ngIf="file && file?.name">File to upload: {{ file.name }}</span>
</div>
