import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

export class User {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id?: number;

  /**
   * User email
   */
  @autoserializeAs(() => String) email?: string;

  /**
   * User name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * User last name
   */
  @autoserializeAs(() => String, 'last_name') lastName?: string;

  /**
   * User avatar URL
   */
  @autoserializeAs(() => String, 'avatar_url') avatar: string | null;

  /**
   * Invitation date
   */
  @autoserializeAs(() => Date, 'invitation_sent_at') invitationSentAt?: Date;

  /**
   * User position
   */
  @autoserializeAs(() => String) position?: string;

  /**
   * User role
   */
  @autoserializeAs(() => String) role?: string;

  /**
   * Number of logins
   */
  @autoserializeAs(() => Number, 'login_count') loginCount?: number;

  /**
   * When the user was logged in
   */
  @autoserializeAs(() => Date, 'logged_at') loggedAt?: Date;

  /**
   * Whether the user is disabled
   */
  @autoserializeAs(() => Boolean) disabled?: boolean;

  /**
   * Hack to prevent the mistaken assignment of objects from one class to a different class
   */
  private readonly _user = true;

  constructor(
    id?: number,
    email?: string,
    name?: string,
    lastName?: string,
    avatarUrl = null,
    invitationSentAt?: Date,
    position?: string,
    role?: string,
    loginCount?: number,
    loggedAt?: Date,
    disabled?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.lastName = lastName;
    this.avatar = avatarUrl;
    this.invitationSentAt = invitationSentAt;
    this.position = position;
    this.role = role;
    this.loginCount = loginCount;
    this.disabled = disabled;
    this.loggedAt = loggedAt;
    this.role = role;
  }
}

export class UserActivationAccountData {
  /**
   * User name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * User last name
   */
  @autoserializeAs(() => String, 'last_name') lastName?: string;

  /**
   * User password
   */
  @autoserializeAs(() => String) password?: string;

  constructor(name: string, lastName: string, password: string) {
    this.name = name;
    this.lastName = lastName;
    this.password = password;
  }
}

export class UserList {
  @autoserializeAsArray(() => User) items: User[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: User[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}

export class UserUpdate {
  /**
   * User name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * User last name
   */
  @autoserializeAs(() => String, 'last_name') lastName?: string;

  /**
   * User position
   */
  @autoserializeAs(() => String) position?: string;

  /**
   * User role
   */
  @autoserializeAs(() => String) role?: string;

  constructor(name?: string, lastName?: string, position?: string, role?: string) {
    this.name = name;
    this.lastName = lastName;
    this.position = position;
    this.role = role;
  }
}

export class UserListInvitation {
  /**
   * Email user list
   */
  @autoserializeAsArray(() => String, () => [], 'email_list') email?: string[];

  /**
   * Message to the invited users
   */
  @autoserializeAs(() => String) message?: string;

  /**
   * Hack to prevent the mistaken assignment of objects from one class to a different class
   */
  private readonly _userListInvitation = true;

  constructor(email?: string[], message?: string) {
    this.email = email;
    this.message = message;
  }
}
