/**
 * Shows an empty string when the value is null
 * @param value
 * @param defaultStr
 */
export function displayTextOrDefaultString(value: string | null, defaultStr = ''): string {
  return value ? value : defaultStr;
}

export async function typeWriter(
  text: string,
  index: number,
  delay: number,
  callback: (substring: string) => void
): Promise<void> {
  if (index < text.length) {
    // console.log(text, text.charAt(index));
    callback(text.charAt(index));
    await new Promise<void>((resolve) =>
      setTimeout(() => {
        typeWriter(text, index + 1, delay, callback).then(() => resolve());
      }, delay)
    );
  }
}
