import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Section } from '../../models/section';
import { TinymceEditorComponent } from './tinymce-editor/tinymce-editor.component';

@Component({
  selector: 'app-edit-section',
  templateUrl: './edit-section.component.html',
  styleUrls: ['./edit-section.component.scss'],
  standalone: true,
  imports: [TinymceEditorComponent, MatButtonModule]
})
export class EditSectionComponent {
  @Input() section!: Section;
  @Output() editMode = new EventEmitter<number>();
  @Output() saveSection = new EventEmitter<Section>();

  @ViewChild(TinymceEditorComponent) tinymceEditorComponent!: TinymceEditorComponent;

  changeEditMode(): void {
    this.editMode.emit(this.section.order);
  }

  save(): void {
    this.section.body = this.tinymceEditorComponent.getContent();
    this.saveSection.emit(this.section);
  }
}
