import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { ActiveClientItemComponent } from './active-client-item/active-client-item.component';
import { Client } from '../../models/client';
import { NgFor } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-active-client-list',
  templateUrl: './active-client-list.component.html',
  styleUrls: ['./active-client-list.component.scss'],
  standalone: true,
  imports: [RouterLink, NgFor, ActiveClientItemComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ActiveClientListComponent {
  @Input() clients: Client[] = [];
}
