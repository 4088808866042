<form class="form-wrapper fill gap-lg" [formGroup]="form" (submit)="endStep()">
  <section class="gap column">
    <section class="column gap-sm">
      <span>Application name</span>
      <mat-form-field class="form-input">
        <input matInput placeholder="e.g. Lab Grown Leather" formControlName="name" required />
        <mat-error *ngIf="form.controls.name.hasError('required')">
          Please enter a name for your application
        </mat-error>
      </mat-form-field>
    </section>
    <section class="column gap-sm">
      <span>Project Number (Auto-assigned)</span>
      <mat-form-field class="form-input">
        <input matInput formControlName="projectNumber" />
        <mat-hint>Your unique identifier for tracking this project</mat-hint>
      </mat-form-field>
    </section>
  </section>

  <section class="horizontal-start fill column gap">
    <section class="column">
      <span>Choose client</span>
      <span class="description">
        Choose a client for whom you’re creating this application. The selected client’s details will be used for
        application customization.
      </span>
    </section>
    <mat-divider class="fill"></mat-divider>
  </section>

  <section class="two-columns center gap">
    <div class="checkbox" *ngFor="let checkbox of checkboxes">
      <mat-checkbox [checked]="checkbox.checked" (change)="selectClient(checkbox.client.id)">
        <div class="column gap-sm">
          <span>{{ checkbox.client.name }}</span>
          <span class="description">{{ checkbox.client.sector }}</span>
        </div>
      </mat-checkbox>
    </div>
  </section>
</form>
<div class="row horizontal-end fill">
  <button
    mat-button
    type="submit"
    class="btn btn--primary"
    [disabled]="!checkIfClientIsSelected() || !form.valid"
    (click)="endStep()"
  >
    Next
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </button>
</div>
