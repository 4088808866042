import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TenderOffers, TenderOffersList } from '../models/tender-offers';
import { ApiService } from './api.service';
import { Document } from '../models/document';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Offer } from '../models/offer';
import { QueryParamsInterface } from '@components/ng-dynamic-http-table';
import { Subject } from 'rxjs/';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TenderOffersService {
  /**
   * API path
   */
  path = '/offer';

  private saveSubject = new Subject<boolean>();

  save$ = this.saveSubject.asObservable();

  /**
   * Constructor
   * @param http - HTTP client service
   * @param apiService - API service
   */
  constructor(
    protected http: HttpClient,
    private apiService: ApiService
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  /**
   * List all Tender Offers
   * @param apsParams - Query params
   */
  list(apsParams: QueryParamsInterface): Observable<TenderOffersList> {
    const sortOptions: { [key: string]: string } = {
      /* eslint-disable @typescript-eslint/naming-convention */
      '+name': '+name',
      '-name': '-name',
      '+projectNumber': '+id',
      '-projectNumber': '-id',
      '+clientName': '+client_id',
      '-clientName': '-client_id',
      '+status': '+status',
      '-status': '-status'
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    apsParams.sort = sortOptions[apsParams.sort ?? '-projectNumber'];
    const params = new HttpParams({
      fromObject: { ...apsParams }
    });

    return this.http
      .get<IJsonObject>(`${this.path}/tender-offers`, { params })
      .pipe(map((offerList) => Deserialize(offerList, () => TenderOffersList)));
  }

  /**
   * Get offers with a status
   */
  listByStatus(status: string): Observable<TenderOffersList> {
    return this.http
      .get<IJsonObject>(`${this.path}/tender-offers/${status}`)
      .pipe(map((offerList) => Deserialize(offerList, () => TenderOffersList)));
  }

  /**
   * Get all offers published
   */
  listPublished(): Observable<TenderOffersList> {
    return this.http
      .get<IJsonObject>(`${this.path}/tender-offers/published`)
      .pipe(map((offerList) => Deserialize(offerList, () => TenderOffersList)));
  }

  getOfferById(offerId: number): Observable<TenderOffers> {
    return this.http
      .get<IJsonObject>(`${this.path}/tender-offer/${offerId}`)
      .pipe(map((offer) => Deserialize(offer, () => TenderOffers)));
  }

  insertOffer(newOffer: Offer): Observable<Offer> {
    return this.http
      .post<IJsonObject>(
        `${this.path}`,
        Serialize(newOffer, () => Offer)
      )
      .pipe(map((offer) => Deserialize(offer, () => Offer)));
  }

  exportToDocx(offerId: number | undefined): Observable<Document> {
    return this.http
      .get<IJsonObject>(`${this.path}/docx/${offerId}`)
      .pipe(map((document) => Deserialize(document, () => Document)));
  }

  patchOffer(offer: Offer): Observable<Offer> {
    return this.http
      .patch<IJsonObject>(
        `${this.path}/${offer.id}`,
        Serialize(offer, () => Offer)
      )
      .pipe(map((offer) => Deserialize(offer, () => Offer)));
  }

  /**
   * Delete offer method
   */
  deleteOffer(offer: Offer): Observable<Offer> {
    return this.http
      .delete<IJsonObject>(`${this.path}/${offer.id}`)
      .pipe(map((offer) => Deserialize(offer, () => Offer)));
  }

  saveAsDraft(): void {
    this.saveSubject.next(true);
  }

  updateOffer(offer: Offer): Observable<Offer> {
    return this.http
      .patch<IJsonObject>(
        `${this.path}/${offer.id}`,
        Serialize(offer, () => Offer)
      )
      .pipe(map((offer) => Deserialize(offer, () => Offer)));
  }
}
