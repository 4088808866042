import { autoserializeAs } from 'dcerialize';

export class Company {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id?: number;

  /**
   * Name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * Address
   */
  @autoserializeAs(() => String) address?: string;

  /**
   * Logo URL
   */
  @autoserializeAs(() => String, 'logo_url') logoUrl?: string;

  /**
   * Tax ID
   */
  @autoserializeAs(() => String, 'tax_id') taxId?: string;

  /**
   * The company contact email
   */
  @autoserializeAs(() => String, 'contact_person') contactPerson?: string;

  /**
   * The company contact phone
   */
  @autoserializeAs(() => String, 'contact_phone') contactPhone?: string;

  /**
   * The company contact email
   */
  @autoserializeAs(() => String, 'contact_email') contactEmail?: string;

  /**
   * Constructor
   *
   * @param id
   * @param name
   * @param logoUrl
   * @param taxId
   */

  /**
   * General chat feature flag
   */
  @autoserializeAs(() => Boolean, 'general_chat_feature') generalChatFeature?: boolean;

  /**
   * Grant generator feature flag
   */
  @autoserializeAs(() => Boolean, 'grant_generator_feature') grantGeneratorFeature?: boolean;

  constructor(
    id?: number,
    name?: string,
    logoUrl?: string,
    taxId?: string,
    generalChatFeature?: boolean,
    grantGeneratorFeature?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.logoUrl = logoUrl;
    this.taxId = taxId;
    this.generalChatFeature = generalChatFeature;
    this.grantGeneratorFeature = grantGeneratorFeature;
  }
}
