import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { Section } from '../../../models/section';

@Component({
  selector: 'app-section-item',
  templateUrl: './section-item.component.html',
  styleUrls: ['./section-item.component.scss'],
  standalone: true,
  imports: [CdkDragPlaceholder, NgIf, MatButtonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SectionItemComponent implements OnChanges {
  @Input() section!: Section;
  @Input() editMode = false;

  @Output() deleteSectionEmitter = new EventEmitter<number>();
  @Output() editModeEmitter = new EventEmitter<number>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  changeEditMode(): void {
    this.editModeEmitter.emit(this.section.order);
  }

  deleteSection(): void {
    this.deleteSectionEmitter.emit(this.section.id);
  }
}
