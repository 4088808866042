import { autoserializeAs, autoserializeAsArray, onDeserialized } from 'dcerialize';
import { Offer } from './offer';

export class Client {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id?: number;

  /**
   * Name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * Address
   */
  @autoserializeAs(() => String) description?: string;

  /**
   * Sector
   */
  @autoserializeAs(() => String) sector?: string;

  /**
   * Active
   */
  @autoserializeAs(() => String) address?: string;

  /**
   * Active flag
   */
  @autoserializeAs(() => Boolean) active?: boolean;

  /**
   * Client Offers
   */
  @autoserializeAsArray(() => String, () => Array, 'client_offers') clientOffers?: Offer[];

  /**
   * Tax ID
   */
  @autoserializeAs(() => String, 'tax_id') taxId?: string;

  /**
   * Contact Person
   */
  @autoserializeAs(() => String, 'contact_person') contactPerson?: string;

  /**
   * Contact Phone
   */
  @autoserializeAs(() => String, 'contact_phone') contactPhone?: string;

  /**
   * Contact Email
   */
  @autoserializeAs(() => String, 'contact_email') contactEmail?: string;

  private offersQuantity?: number;
  @onDeserialized
  calculateOfferQuantity(): void {
    this.offersQuantity = this.clientOffers?.length;
  }

  constructor(
    id?: number,
    name?: string,
    description?: string,
    address?: string,
    active?: boolean,
    sector?: string,
    taxId?: string,
    contactPerson?: string,
    contactPhone?: string,
    contactEmail?: string
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.address = address;
    this.active = active;
    this.sector = sector;
    this.taxId = taxId;
    this.contactPerson = contactPerson;
    this.contactPhone = contactPhone;
    this.contactEmail = contactEmail;
  }
}

export class ClientList {
  @autoserializeAsArray(() => Client) items: Client[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: Client[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}
