<div class="chat-content">
  <button mat-button *ngIf="hideChatList" (click)="toogleShowChats()" class="btn btn--secondary corner">
    <ion-icon name="list-outline"></ion-icon>
  </button>

  <div *ngFor="let message of messages; let i = index">
    <app-message [message]="message"></app-message>
  </div>

  <div id="anchor"></div>
</div>
<div class="textarea-wrapper margin-top">
  <textarea
    (keydown)="onKeyDown($event)"
    (input)="adjustTextareaSize($event)"
    [style.height.px]="textAreaHeight"
    matInput
    [(ngModel)]="value"
    #conversationInput
    placeholder="Enter a Message"
    class="text-field"
  ></textarea>
  <button type="submit" mat-button (click)="send()" class="btn send-button margin-right">
    <ion-icon name="paper-plane-outline"></ion-icon>
  </button>
</div>
<div class="chat-disclaimer">
  <div>
    Important privacy note: chat contents are not shared with anyone. Chat infrastructure runs fully isolated for your
    client, so content is only yours and not shared with OpenAI, Azure or other cloud or service providers.
  </div>
</div>
