import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DragDropComponent } from '../drag-drop/drag-drop.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-import-application-modal',
  templateUrl: './import-application-modal.component.html',
  styleUrls: ['./import-application-modal.component.scss'],
  standalone: true,
  imports: [MatDividerModule, DragDropComponent, MatButtonModule, MatDialogModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ImportApplicationModalComponent {
  file?: File;

  constructor(private dialogRef: MatDialogRef<ImportApplicationModalComponent>) {}

  closeModal(): void {
    this.dialogRef.close();
  }

  fileDropped(file: File): void {
    this.file = file;
  }

  protected readonly close = close;
}
