import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const slideInLeft = trigger('slideInLeft', [
  transition(':enter', [
    animate(
      '0.2s',
      keyframes([
        style({ transform: 'translateX(-100%)', offset: 0 }),
        style({ transform: 'translateX(0)', offset: 1.0 })
      ])
    )
  ])
]);

export const slideOutLeft = trigger('slideOutLeft', [
  transition(':leave', [
    animate(
      '0.2s',
      keyframes([
        style({ transform: 'translateX(0)', offset: 0 }),
        style({ transform: 'translateX(-100%)', offset: 1.0 })
      ])
    )
  ])
]);

export const slideInLeftNew = trigger('slideInLeftNew', [
  state('new', style({ transform: 'translateX(0)' })),
  transition('* => new', [
    animate(
      '0.2s ease-in',
      keyframes([
        style({ transform: 'translateX(-100%)', offset: 0 }),
        style({ transform: 'translateX(0)', offset: 1.0 })
      ])
    )
  ])
]);

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    animate(
      '0.3s',
      keyframes([style({ opacity: 0, position: 'fixed', offset: 0 }), style({ opacity: 1, offset: 1.0 })])
    )
  ])
]);

export const fadeOut = trigger('fadeOut', [
  transition(':leave', [
    animate(
      '0.3s',
      keyframes([style({ opacity: 1, position: 'fixed', offset: 0 }), style({ opacity: 0, offset: 1.0 })])
    )
  ])
]);

export const expandWidthLeft = trigger('expandWidthLeft', [
  transition('collapsed => expanded', [
    animate(
      '0.3s',
      keyframes([
        // Start at original position
        style({ transform: 'scaleX(1)', transformOrigin: 'right', zIndex: 10, offset: 0 }),
        style({ transform: 'scaleX(1.10)', zIndex: 10, offset: 0.75 }),
        // Expand to modified position
        style({ transform: 'scaleX(1.15)', zIndex: 10, offset: 1.0 })
      ])
    )
  ]),
  transition('expanded => collapsed', [
    animate(
      '0.3s',
      keyframes([
        // Start at modified position
        style({ transform: 'scaleX(1)', transformOrigin: 'right', zIndex: 10, offset: 0 }),
        // Return to original position
        style({ transform: 'scaleX(0.85)', zIndex: 10, offset: 1.0 })
      ])
    )
  ])
]);

export const rotate180 = trigger('rotate180', [
  transition('collapsed => expanded', [
    animate(
      '0.2s',
      keyframes([
        // Start at original position
        style({ transform: 'rotate(0deg)', offset: 0 }),
        // Expand to modified position
        style({ transform: 'rotate(180deg)', offset: 1.0 })
      ])
    )
  ]),
  transition('expanded => collapsed', [
    animate(
      '0.2s',
      keyframes([
        // Start at modified position
        style({ transform: 'rotate(180deg)', offset: 0 }),
        // Return to original position
        style({ transform: 'rotate(0deg)', offset: 1.0 })
      ])
    )
  ])
]);

export const navbarHighlight = trigger('navbarHighlight', [
  state('inactive', style({ transform: 'scale(0.95)' })),
  state('active', style({ transform: 'scale(1.05)' })),
  transition('inactive => active', [
    animate(
      '0.2s',
      keyframes([
        style({ transform: 'scale(0.95)', offset: 0 }),
        style({ transform: 'scale(1)', offset: 0.5 }),
        style({ transform: 'scale(1.05)', offset: 1.0 })
      ])
    )
  ]),
  transition('active => inactive', [
    animate(
      '0.15s',
      keyframes([
        style({ transform: 'scale(1.05)', offset: 0 }),
        style({ transform: 'scale(1)', offset: 0.5 }),
        style({ transform: 'scale(0.95)', offset: 1.0 })
      ])
    )
  ])
]);
