import { EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { Section } from '../../../models/section';

declare let tinymce: any;

@Component({
  selector: 'app-tinymce-editor',
  templateUrl: './tinymce-editor.component.html',
  styleUrls: ['./tinymce-editor.component.scss'],
  standalone: true
})
export class TinymceEditorComponent implements OnInit, OnDestroy {
  @Input() content: Section = {
    id: -1,
    title: '',
    body: '',
    order: -1,
    offerId: -1
  };
  @Output() contentChange = new EventEmitter<any>();
  editor: any;
  ngOnInit(): void {
    tinymce.init({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      entity_encoding: 'raw',
      selector: 'textarea',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      base_url: '/tinymce',
      suffix: '.min',
      formats: {
        underline: { inline: 'span', classes: 'underline' },
        code: { inline: 'code', classes: 'code' }
      },
      setup: (editor: any) => {
        this.editor = editor;
      }
    });
  }

  ngOnDestroy(): void {
    tinymce.remove('textarea');
  }

  getContent(): any {
    return this.editor.getContent();
  }
}
