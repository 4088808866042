import { Component, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '../../../utils/constants';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { matchValidator } from '../../../utils/validators';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatButtonModule]
})
export class RestorePasswordComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly PASSWORD_MIN_LENGTH = PASSWORD_MIN_LENGTH;

  token?: string;

  tokenValid = false;

  form: FormGroup = new FormGroup({});

  constructor(
    private builder: NonNullableFormBuilder,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const splitUrl = this.router.url.split('/');

    this.token = splitUrl[splitUrl.length - 1];
    this.userService.validateResetPasswordToken(this.token).subscribe(() => {
      this.tokenValid = true;
    });

    this.form = this.builder.group({
      password: this.builder.control<string>('', [
        Validators.required,
        Validators.minLength(PASSWORD_MIN_LENGTH),
        Validators.pattern(PASSWORD_REGEX),
        matchValidator('confirmPassword', true)
      ]),
      confirmPassword: this.builder.control<string>('', [Validators.required, matchValidator('password')])
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const password = this.form.value.password;
      const confirmPassword = this.form.value.confirmPassword;

      if (password === confirmPassword) {
        this.userService.setPassword(password, this.token).subscribe();
      }
    }
  }
}
