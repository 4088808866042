import { FormArray, FormControl } from '@angular/forms';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { CustomSnackbarService } from '../services/custom-snackbar.service';

/**
 * Default Form Control creation
 * @param value - Value for the created form control
 * @returns - The created form control
 */
export const defaultFormControl = (value: string): FormControl<string> => {
  return new FormControl<string>(value, { nonNullable: true });
};

/**
 * Add an element in the MatChipGrid.
 * It's needed a function with the FormControl creation because depending on the
 * case you would need some validators or not
 * @param event - MatChipInputEvent with the introduced value
 * @param formArray - [Will be modified] FormArray where the event should be added
 * @param inputCreation - Function with the FormControl creation, by default is defaultFormControl
 * @param snackbar - Snackbar service to inform the user if email is invalid
 */
export const addInputMatChipGrid = (
  event: MatChipInputEvent,
  formArray: FormArray<FormControl<string>>,
  inputCreation: (value: string) => FormControl<string> = defaultFormControl,
  snackbar: CustomSnackbarService
): void => {
  const control = inputCreation(event.value.trim());

  if (control.status == 'VALID') {
    formArray.push(control);
  } else {
    snackbar.present('Invalid email');
  }

  formArray.parent?.updateValueAndValidity();
  event.chipInput?.clear();
};

/**
 * Edit an element from the MatChipGrid.
 * @param event - MatChipEditedEvent with the introduced value
 * @param index - Position of the edited chip
 * @param formArray - [Will be modified] FormArray where the value should be edited
 */
export const editInputMatChipGrid = (
  event: MatChipEditedEvent,
  index: number,
  formArray: FormArray<FormControl<string>>
): void => {
  if (event.value) {
    formArray.at(index).setValue(event.value);
  }

  formArray.parent?.updateValueAndValidity();
};

/**
 * Remove an element from the MatChipGrid.
 * @param index - Position of the removed chip
 * @param formArray - [Will be modified] FormArray where the value should be removed
 */
export const removeInputMatChipGrid = (index: number, formArray: FormArray<FormControl<string>>): void => {
  if (index >= 0) {
    formArray.removeAt(index);
  }
};

/**
 * Paste a list of elements in the MatChipGrid.
 * It's needed a function with the FormControl creation because depending on the
 * case you would need some validators or not
 * @param event - ClipboardEvent with the introduced values
 * @param formArray - [Will be modified] FormArray where the value should be added
 * @param inputCreation - Function with the FormControl creation, by default is defaultFormControl
 * @param snackbar - Snackbar service to inform the user if email is invalid
 */
export const pasteInputMatChipGrid = (
  event: ClipboardEvent,
  formArray: FormArray<FormControl<string>>,
  inputCreation: (value: string) => FormControl<string> = defaultFormControl,
  snackbar: CustomSnackbarService
): void => {
  event.preventDefault();
  event.clipboardData
    ?.getData('Text')
    .split(/;|,|\n/)
    .forEach((value) => {
      if (value.trim()) {
        addInputMatChipGrid({ value: value } as MatChipInputEvent, formArray, inputCreation, snackbar);
      }
    });
};
