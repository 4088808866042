import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Company } from '../models/company';
import { CompanyFeaturesInterface } from '../interfaces/company-features.interface';
import { CompanyService } from './company.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';

/**
 * Guard to be used in routes definition, field 'canActivate'
 */
@Injectable({
  providedIn: 'root'
})
export class FeaturesGuard {
  /**
   * Constructor
   * @param companyService
   * @param router
   */
  constructor(
    private companyService: CompanyService,
    private router: Router
  ) {}

  /**
   * Whether the current user can access to a view or not, depending on the company feature flags
   * @returns {boolean}
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const featureIdentifier: CompanyFeaturesInterface = route.data.feature;

    this.companyService.getCompany().subscribe((company: Company) => {
      const featureEnabled = company[featureIdentifier];

      if (!featureEnabled) {
        this.router.navigateByUrl(route.data.fallbackUrl ? route.data.fallbackUrl : '');
      }
    });

    return of(true);
  }
}
