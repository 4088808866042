import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { Injectable, NgZone } from '@angular/core';
import { Message, MessageList } from '../models/message';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  /**
   * API path
   */
  path = '/message';

  private eventSource!: EventSource;

  /**
   * Constructor
   * @param http - HTTP client service
   * @param apiService
   * @param zone
   */
  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private zone: NgZone
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  createMessage(newMessage: Message): Observable<Message> {
    return this.http
      .post<IJsonObject>(
        `${this.path}`,
        Serialize(newMessage, () => Message)
      )
      .pipe(map((message) => Deserialize(message, () => Message)));
  }

  getAnswer(chatId?: number): Observable<any> {
    const url = `${this.path}/bot/${chatId}/answer`;

    return new Observable((observer) => {
      const eventSource = new EventSource(url, { withCredentials: true });

      eventSource.onmessage = (event) => {
        this.zone.run(() => {
          const contentFormated = event.data.replace(/\\n/g, '\n');
          observer.next(contentFormated);
        });
      };

      eventSource.onerror = (error) => {
        this.zone.run(() => {
          eventSource.close();
          observer.error(error);
        });
      };
    });
  }

  getMessagesFromChat(chatId?: number): Observable<MessageList> {
    return this.http
      .get<IJsonObject>(`${this.path}/chat/${chatId}`)
      .pipe(map((messages) => Deserialize(messages, () => MessageList)));
  }

  deleteMessage(messageId?: number): Observable<void> {
    return this.http.delete<void>(`${this.path}/${messageId}`);
  }
}
