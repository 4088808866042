import { Component, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CompanyService } from '../../../services/company.service';
import { CustomSnackbarService } from '../../../services/custom-snackbar.service';
import { DragDropComponent } from '../../drag-drop/drag-drop.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LogoService } from '../../../services/logo.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons/faCloudUpload';

@Component({
  selector: 'app-company-info-form',
  templateUrl: './company-info-form.component.html',
  styleUrls: ['./company-info-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    DragDropComponent,
    FontAwesomeModule
  ]
})
export class CompanyInfoFormComponent implements OnInit {
  protected readonly faCloudUpload = faCloudUpload;

  form!: FormGroup;

  logo?: File;

  previewLogo?: string | ArrayBuffer | null;

  constructor(
    private companyService: CompanyService,
    private builder: NonNullableFormBuilder,
    private snackbar: CustomSnackbarService,
    private logoService: LogoService
  ) {
    this.form = this.builder.group({
      name: this.builder.control('', [Validators.required]),
      address: this.builder.control('', [Validators.required]),
      taxId: this.builder.control('', [Validators.required]),
      contactPerson: this.builder.control('', [Validators.required]),
      contactPhone: this.builder.control('', [Validators.required]),
      contactEmail: this.builder.control('', [Validators.required, Validators.email])
    });
  }

  ngOnInit(): void {
    this.companyService.getCompany().subscribe((company) => {
      this.form.patchValue(company);
    });

    this.form.controls.name.disable();
  }

  submit(): void {
    this.companyService.updateCompany(this.form.value).subscribe(() => {
      this.snackbar.present('Company updated');
    });
  }

  submitLogo(): void {
    if (this.logo) {
      this.companyService.updateLogo(this.logo).subscribe(() => {
        this.logoService.updateData(this.previewLogo as string);
        this.logo = undefined;
        this.previewLogo = undefined;
        this.snackbar.present('Logo updated');
      });
    }
  }

  saveLogo(logo: File): void {
    this.logo = logo;
    const reader = new FileReader();
    reader.onload = () => {
      this.previewLogo = reader.result;
    };
    reader.readAsDataURL(logo);
  }
}
