import { Deserialize, IJsonObject } from 'dcerialize';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { DocumentList } from '../models/document';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { QueryParamsInterface } from '@components/ng-dynamic-http-table';
import { Subject } from 'rxjs/';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  /**
   * API path
   */
  path = '/document';

  documentUploaded = new Subject<boolean>();

  /**
   * Constructor
   * @param http - HTTP client service
   * @param apiService - API service
   */
  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  /**
   * Insert document
   */
  insertDocument(doc: FormData, offerId?: number): Observable<any> {
    if (offerId) {
      return this.http.put<IJsonObject>(`${this.path}/${offerId}`, doc);
    } else {
      return this.http.put<IJsonObject>(`${this.path}/`, doc);
    }
  }

  /**
   * Get all documents uploaded
   */
  getDocuments(apsParams: QueryParamsInterface): Observable<DocumentList> {
    const sortOptions: { [key: string]: string } = {
      /* eslint-disable @typescript-eslint/naming-convention */
      '+filename': '+filename',
      '-filename': '-filename',
      '+createdAt': '+created_at',
      '-createdAt': '-created_at',
      '+size': '+size',
      '-size': '-size'
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    apsParams.sort = sortOptions[apsParams.sort ?? '+filename'];
    const params = new HttpParams({
      fromObject: { ...apsParams }
    });

    return this.http
      .get<IJsonObject>(`${this.path}`, { params })
      .pipe(map((document) => Deserialize(document, () => DocumentList)));
  }

  /**
   * Get all the documents of an offer
   */
  getDocumentsByOfferId(offerId?: number): Observable<DocumentList> {
    return this.http
      .get<IJsonObject>(`${this.path}/offer/${offerId}`)
      .pipe(map((document) => Deserialize(document, () => DocumentList)));
  }

  /**
   * Delete a document
   */
  deleteDocument(documentId?: number): Observable<any> {
    return this.http.delete<IJsonObject>(`${this.path}/${documentId}`);
  }

  /**
   * Download a document
   */
  downloadDocument(documentId?: number): string {
    return `${this.path}/${documentId}/download`;
  }
}
