<mat-button-toggle-group class="selector" #selectedTab="matButtonToggleGroup" [value]="ApplicationTabs.GENERATED">
  <mat-button-toggle
    class="row gap-sm text-sm vertical-center"
    [value]="ApplicationTabs.GENERATED"
    aria-label="Applications"
  >
    <ion-icon name="flash-outline"></ion-icon>
    Generated applications
  </mat-button-toggle>
  <mat-button-toggle
    class="row gap-sm text-sm vertical-center"
    [value]="ApplicationTabs.HISTORY"
    aria-label="Applications"
  >
    <ion-icon name="archive-outline"></ion-icon>
    Manual applications
  </mat-button-toggle>
</mat-button-toggle-group>
<div class="column gap" *ngIf="selectedTab.value === ApplicationTabs.GENERATED">
  <app-application-list [link]="false" [subtitle]="subtitleList" [pageSize]="10"></app-application-list>
  <section>
    <button mat-button class="btn btn--primary btn--large" routerLink="/new-tender">
      <div class="row vertical-center gap-sm">
        <span class="semi-bold">Create application</span>
        <ion-icon name="add-outline"></ion-icon>
      </div>
    </button>
  </section>
</div>
<div class="column gap" *ngIf="selectedTab.value === ApplicationTabs.HISTORY">
  <app-application-history [link]="false" [subtitle]="subtitleHistory"></app-application-history>
  <section>
    <button (click)="importApplication()" mat-button class="btn btn--primary row gap">
      <ion-icon name="cloud-upload-outline"></ion-icon>
      <span class="semi-bold">Import application</span>
    </button>
  </section>
</div>
