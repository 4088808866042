<div class="auth-container column gap">
  <h2 class="auth-container-title horizontal-center no-margin">Forgot Password</h2>
  <form class="form-container">
    <mat-label>Email</mat-label>
    <mat-form-field>
      <input matInput type="email" [formControl]="email" placeholder="john.doe@company.com" required />
      <mat-error *ngIf="email.hasError('required')">The email is required</mat-error>
      <mat-error *ngIf="email.hasError('email')">Please enter a valid email address</mat-error>
    </mat-form-field>
    <div class="center">
      <button (click)="onSubmit()" [disabled]="!email.valid" class="btn btn--primary btn--lg" mat-button type="submit">
        Submit
      </button>
    </div>
  </form>
</div>
