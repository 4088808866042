import { BehaviorSubject } from 'rxjs/';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoService {
  private dataSubject = new BehaviorSubject<string>('');
  data$ = this.dataSubject.asObservable();

  updateData(data: string): void {
    this.dataSubject.next(data);
  }
}
