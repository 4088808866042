import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { ApiService } from './api.service';
import { Company } from '../models/company';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  /**
   * API path
   */
  path = '/company';

  /**
   * Constructor
   * @param http - HTTP client service
   * @param apiService - API service
   */
  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  /**
   * Get the company
   */
  getCompany(): Observable<Company> {
    return this.http
      .get<IJsonObject>(`${this.path}/current`)
      .pipe(map((company) => Deserialize(company, () => Company)));
  }

  /**
   * Update the company
   */
  updateCompany(company: Company): Observable<Company> {
    return this.http
      .patch<IJsonObject>(
        `${this.path}`,
        Serialize(company, () => Company)
      )
      .pipe(map((company) => Deserialize(company, () => Company)));
  }

  /**
   * Update Logo
   */
  updateLogo(logo: File): Observable<Company> {
    const formData = new FormData();
    formData.append('logo', logo);

    return this.http
      .put<IJsonObject>(`${this.path}/logo`, formData)
      .pipe(map((company) => Deserialize(company, () => Company)));
  }

  getLogo(): string {
    return `${this.path}/download-logo`;
  }
}
