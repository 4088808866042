<div *ngIf="isLoggedIn">
  <mat-toolbar class="navbar">
    <mat-toolbar-row>
      <a routerLink="/" class="company-logo">
        <img class="company-logo-image" [src]="companyLogo" />
        <span><img class="favicon" src="favicon.svg" /> {{ title }}</span>
      </a>
      <div class="row fill navbar-space" *ngIf="!fullNavbar">
        <section class="navbar-section">
          <ng-container *ngIf="navbarItems.length > 1">
            <app-navbar-item
              [@navbarHighlight]="item.active ? 'active' : 'inactive'"
              *ngFor="let item of navbarItems"
              [item]="item"
            ></app-navbar-item>
          </ng-container>
        </section>
        <section class="row gap-sm vertical-center">
          <button
            *ngIf="hasGrantGeneratorFeature"
            mat-button
            class="btn btn--primary"
            routerLink="/new-tender"
            routerLinkActive="active"
          >
            Generate <ion-icon name="add-outline"></ion-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <ion-icon class="gray-color-500" name="person-circle-outline"></ion-icon>
            <ion-icon
              class="gray-color-500"
              [class.rotated]="menuOpen"
              [@rotate180]="menuOpen ? 'expanded' : 'collapsed'"
              name="chevron-down-outline"
            ></ion-icon>
          </button>
        </section>
      </div>

      <div class="row fill navbar-space" *ngIf="fullNavbar">
        <section class="row center gap">
          <button mat-icon-button class="navbar-btn no-margin" (click)="goBack()">
            <ion-icon class="icon-md gray-color-500" name="chevron-back-outline"></ion-icon>
          </button>
          <span class="text-md">{{ title }}</span>
        </section>
        <section class="row gap-sm vertical-center">
          <button *ngIf="canBeDraft" (click)="saveAsDraft()" mat-button class="mat-primary">
            Save as a draft <ion-icon name="save-outline"></ion-icon>
          </button>
          <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
            <ion-icon class="gray-color-500" name="person-circle-outline"></ion-icon>
            <ion-icon class="gray-color-500" name="chevron-down-outline"></ion-icon>
          </button>
        </section>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="goToSettings()">
          <span>Settings</span>
        </button>
        <button mat-menu-item disabled>
          <span>Help</span>
        </button>
        <button class="error" mat-menu-item (click)="logout()">
          <span>Logout</span>
        </button>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="!isLoggedIn">
  <mat-toolbar class="navbar">
    <mat-toolbar-row class="row fill">
      <div class="company-logo">
        <img class="company-logo-image" [src]="companyLogo" />
        <span><img class="favicon" src="favicon.svg" /> {{ title }}</span>
      </div>
      <section class="row center gap" *ngIf="canGoBack">
        <button mat-icon-button class="navbar-btn no-margin" (click)="goBack()">
          <ion-icon class="icon-md gray-color-500" name="chevron-back-outline"></ion-icon>
        </button>
        <span class="text-md">Back</span>
      </section>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
