<div>
  <h1>{{ offer.description }}</h1>
  <div class="background">
    <div class="container">
      <div class="canvas">
        <div class="element">
          <p class="offer-details-contact">Offer Details</p>
          <div class="data">
            <div class="grey">Status</div>
            <div class="published" *ngIf="published">{{ offer.status }}</div>
            <div class="draft" *ngIf="!published">{{ offer.status }}</div>
          </div>
          <div class="data">
            <div class="grey">Date Issued</div>
            <div class="value">{{ offer.createdAt | date }}</div>
          </div>
          <div class="data">
            <div class="grey">Deadline</div>
            <div class="value">{{ offer.deadline | date }}</div>
          </div>
          <div class="contact-header">
            <p class="offer-details-contact">Contacts</p>
            <a class="view-more">
              View client
              <fa-icon [icon]="faAngleRight"></fa-icon>
            </a>
          </div>
          <div class="contact">
            <p>{{ client.name }}</p>
            <a href="mailto:{{ client.contactEmail }}">{{ client.contactEmail }}</a>
            <p>{{ client.contactPhone }}</p>
            <p class="address">{{ client.address }}</p>
          </div>
        </div>
        <div class="right-element">
          <div class="toggle-activity-notes">
            <mat-button-toggle-group [(ngModel)]="selectedOption">
              <mat-button-toggle class="toggle" value="Activity">Activity</mat-button-toggle>
              <mat-button-toggle class="toggle" value="Notes">Notes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div *ngIf="selectedOption === 'Activity'" class="notes-activity">
            <h2>
              <fa-icon class="icon" [icon]="faBolt"></fa-icon>
              Activity
            </h2>
            <div class="activity-list">
              <ng-container *ngFor="let activity of activities; let i = index">
                <div class="activity-item" *ngIf="i < 3">
                  <div class="activity-description">
                    {{ activity.description }}
                  </div>
                  <div class="activity-author">
                    {{ activity.author }}
                  </div>
                  <div class="activity-author">
                    {{ activity.date }}
                  </div>
                </div>
              </ng-container>
            </div>
            <a class="view-more">
              View more
              <fa-icon [icon]="faAngleRight"></fa-icon>
            </a>
          </div>

          <div *ngIf="selectedOption === 'Notes'" class="notes-activity">
            <div class="chat-header">
              <div class="chat-title">
                <h2>
                  <fa-icon class="icon" [icon]="faSquareCheck"></fa-icon>
                  Notes
                </h2>
              </div>
              <a class="chat-button">
                <button mat-button class="mat-accent">
                  New note
                  <fa-icon [icon]="faPlus"></fa-icon>
                </button>
              </a>
            </div>
            <div class="notes" *ngFor="let note of notes; let i = index">
              <div class="notes-description" *ngIf="i < 2" (click)="openPopUp(note)">
                <div class="notes-description-title">
                  {{ note.title }}
                </div>
                <div class="notes-description-info">
                  {{ note.date }}
                </div>
                <div class="notes-description-info">
                  {{ note.author }}
                </div>
              </div>
              <div class="notes-arrow" *ngIf="i < 2">
                <fa-icon [icon]="faAngleRight"></fa-icon>
              </div>
            </div>
            <div class="view-more">
              View more
              <fa-icon [icon]="faAngleRight"></fa-icon>
            </div>
          </div>
        </div>
        <div class="popup" *ngIf="showPopup">
          <div class="popup-content">
            <h2>Note</h2>
            <p>Write your note</p>
            <div class="text">
              <mat-form-field style="width: 100%">
                <textarea matInput type="text" rows="10" [(ngModel)]="noteContent"> {{ noteContent }}</textarea>
              </mat-form-field>
            </div>
            <div class="popup-buttons">
              <button (click)="discardChanges()" mat-button class="mat-primary">Discard changes</button>
              <button (click)="saveNote()" mat-button class="mat-accent">Save note</button>
            </div>
          </div>
        </div>
      </div>
      <div class="canvas">
        <div class="element">
          <h2>
            <fa-icon class="icon" [icon]="faFile"></fa-icon>
            Documents
          </h2>
          <div class="canvas" *ngFor="let doc of docs">
            <div class="documents">
              <div class="docs">
                <div class="title">{{ doc.title }}</div>
                <div class="fileName">{{ doc.fileName }}</div>
              </div>
              <div class="doc-buttons">
                <button mat-button (click)="downloadDocument()" class="arrow">
                  <fa-icon [icon]="faArrowDown"></fa-icon>
                </button>
                <button (click)="deleteDocument(doc)" mat-button class="trash">
                  <fa-icon [icon]="faTrashCan"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <button mat-button class="mat-accent">
            Attach document
            <fa-icon [icon]="faPlus"></fa-icon>
          </button>
        </div>
        <div class="right-element">
          <div class="notes-activity">
            <div class="chat-header">
              <div class="chat-title">
                <h2>
                  <fa-icon class="icon" [icon]="faComment"></fa-icon>
                  Chats
                </h2>
              </div>
              <div class="chat-button">
                <button mat-button class="mat-accent">
                  New chat
                  <fa-icon [icon]="faPlus"></fa-icon>
                </button>
              </div>
            </div>
            <div class="notes" *ngFor="let chat of chats; let i = index">
              <div class="notes-description" *ngIf="i < 3">
                <div class="notes-description-title">
                  {{ chat.title }}
                </div>
                <div class="notes-description-info">
                  {{ chat.date }}
                </div>
                <div class="notes-description-info">
                  {{ chat.author }}
                </div>
              </div>
              <div class="notes-arrow" *ngIf="i < 3">
                <fa-icon [icon]="faAngleRight"></fa-icon>
              </div>
            </div>
            <a class="view-more">
              View past conversations
              <fa-icon [icon]="faAngleRight"></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
