import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { ApplicationStages } from '../../../interfaces/application.interface';
import { Client } from '../../../models/client';
import { ClientCheckboxInterface } from '../../../interfaces/client.interface';
import { ClientService } from '../../../services/client.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Offer } from '../../../models/offer';

@Component({
  selector: 'app-tender-offer-form',
  templateUrl: './tender-offer-form.component.html',
  styleUrls: ['./tender-offer-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatDividerModule,
    NgFor,
    MatCheckboxModule,
    MatButtonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TenderOfferFormComponent implements OnInit, OnChanges {
  checkboxes: ClientCheckboxInterface[] = [];

  form: FormGroup;
  @Output() nextStep = new EventEmitter<number>();
  @Output() selectedClient = new EventEmitter<Client>();

  @Input() offer!: Offer;

  constructor(
    private clientService: ClientService,
    private builder: NonNullableFormBuilder
  ) {
    this.form = this.builder.group({
      name: this.builder.control('', [Validators.required]),
      projectNumber: this.builder.control({ value: '', disabled: true })
    });

    this.form.controls.name.valueChanges.subscribe((value) => {
      this.offer.name = value;
    });
  }

  ngOnInit(): void {
    this.clientService.list().subscribe((res) => {
      res.items.forEach((client) => {
        this.checkboxes.push({
          client,
          checked: false
        });
      });

      if (this.offer.clientId) {
        this.selectClient(this.offer.clientId);
      }
    });

    if (this.offer) {
      this.form.patchValue(this.offer);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.offer) {
      this.form.patchValue(changes.offer.currentValue);
    }
  }

  endStep(): void {
    this.nextStep.emit(ApplicationStages.DETAILS);
  }

  selectClient(id?: number): void {
    if (id) {
      this.checkboxes.forEach((client) => {
        if (client.client.id === id) {
          this.selectedClient.emit(client.client);
          client.checked = !client.checked;
        } else {
          client.checked = false;
        }
      });
    }
  }

  checkIfClientIsSelected(): boolean {
    return this.checkboxes.some((client) => client.checked);
  }
}
