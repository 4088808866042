<div class="column">
  <div class="section-header padding-sm">
    <button mat-button (click)="createNewChat()" class="btn btn--primary">
      New chat
      <ion-icon name="add-outline"></ion-icon>
    </button>
    <button mat-button class="btn btn--secondary" (click)="toogleShowChats()">
      <ion-icon name="list-outline"></ion-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="chat-list">
    <ng-container *ngFor="let chat of chats">
      <div
        [class]="activeChat?.id === chat.id ? 'chat-info chat-info__active' : 'chat-info'"
        (click)="changeActiveChat(chat)"
      >
        <section class="chat-info-header" [@slideInLeftNew]="isNew(chat) ? 'new' : ''">
          <h4 class="chat-info-title no-margin">
            <ion-icon class="margin-right" name="chatbubbles-outline"></ion-icon>
            {{ chat.title }}
          </h4>
          <button class="chat-info-delete" mat-icon-button (click)="deleteChat(chat.id)">
            <ion-icon class="icon-sm no-padding" name="trash-outline"></ion-icon>
          </button>
        </section>
      </div>
    </ng-container>
  </div>
</div>
