<ng-container>
  <form [formGroup]="userDataForm">
    <div class="form-wrapper">
      <mat-label>Name</mat-label>
      <mat-form-field>
        <input matInput placeholder="Name" type="string" formControlName="name" name="name" required />
        <mat-error *ngIf="userDataForm.controls.name.hasError('required')">Name is required</mat-error>
      </mat-form-field>
      <mat-label>Last name</mat-label>
      <mat-form-field>
        <input matInput placeholder="{{ 'Last name' }}" type="string" formControlName="lastName" name="lastName" />
      </mat-form-field>
      <mat-label>Position</mat-label>
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'Position' }}"
          type="string"
          formControlName="position"
          name="position"
          required
        />
        <mat-error *ngIf="userDataForm.controls.position.hasError('required')">Position is required</mat-error>
      </mat-form-field>
      <mat-label>Role</mat-label>
      <mat-form-field>
        <mat-select formControlName="role">
          <mat-option *ngFor="let role of roleList" [value]="role">{{ role }}</mat-option>
        </mat-select>
        <mat-error *ngIf="userDataForm.controls.role.hasError('required')">Role is required</mat-error>
      </mat-form-field>
    </div>
    <br />
    <span class="center">
      <button (click)="onNoClick()" type="button" mat-raised-button color="primary">
        {{ 'Discard' }}
      </button>
      <button
        mat-raised-button
        color="accent"
        [mat-dialog-close]="this.userDataForm.value"
        [disabled]="userDataForm.invalid"
      >
        {{ 'Save' }}
      </button>
    </span>
  </form>
</ng-container>
