<section class="horizontal-space-between vertical-center">
  <h2>Your active clients</h2>
  <a class="all-link vertical-center gap-sm" routerLink="/clients">
    <span>View all clients</span>
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </a>
</section>
<section class="column gap-lg">
  <app-active-client-item *ngFor="let client of clients" [client]="client"></app-active-client-item>
</section>
