import { Component, OnInit } from '@angular/core';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  faAngleRight,
  faArrowDown,
  faBolt,
  faCloudArrowUp,
  faComment,
  faFile,
  faPlus,
  faSquareCheck,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Client } from '../../models/client';
import { ClientService } from '../../services/client.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs/internal/Observable';
import { TenderOffers } from '../../models/tender-offers';
import { TenderOffersService } from '../../services/tender-offers.service';
import { of } from 'rxjs';

interface DocData {
  title: string;
  fileName: string;
  id: number;
}

interface ActivityData {
  date: string;
  author: string;
  description: string;
}

interface ChatData {
  title: string;
  date: string;
  author: string;
}

interface NotesData {
  title: string;
  date: string;
  author: string;
  description: string;
}

@Component({
  selector: 'app-tender-offers-detail',
  templateUrl: './tender-offers-detail.component.html',
  styleUrls: ['./tender-offers-detail.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FontAwesomeModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    NgFor,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    DatePipe
  ]
})
export class TenderOffersDetailComponent implements OnInit {
  protected readonly faCloudArrowUp = faCloudArrowUp;

  docs: DocData[] = [
    {
      title: 'Bid document',
      fileName: 'app_offer_XYZCorp.pdf',
      id: 1
    },
    {
      title: 'Technical Requirements',
      fileName: 'tech_requirements_app.pdf',
      id: 2
    },
    {
      title: 'Annual report',
      fileName: '2023_report.pdf',
      id: 3
    },
    {
      title: 'HR report',
      fileName: 'hr_2023_report.pdf',
      id: 4
    }
  ];

  activities: ActivityData[] = [
    {
      date: '29th July 2023',
      author: 'John Doe',
      description: 'Bid officially opened'
    },
    {
      date: '29th July 2023',
      author: 'John Doe',
      description: 'Uploaded version of Technical Requirements Specification uploaded'
    },
    {
      date: '29th July 2023',
      author: 'John Doe',
      description: 'Final round of proposal submissions expected'
    },
    {
      date: '29th July 2023',
      author: 'John Doe',
      description: 'Fourth'
    }
  ];

  notes: NotesData[] = [
    {
      title: 'Meeting with the company: Jane Smith and Brian Allen',
      author: 'by John Doe',
      date: '15th August 2023',
      description:
        'Met with Jane Smith and their CTO, Brian Allen. ' +
        "They're positive about our technical prowess but have concerns over long-term partnership and support. " +
        'Brian mentioned a past vendor who under-delivered in support. Essential to highlight our support accolades, ' +
        'testimonials, and specific case studies in the revised bid'
    },
    {
      title: 'Updated cost estimates',
      author: 'by John Doe',
      date: '26th August 2023',
      description:
        'Met with Tio Jolito and their CTO, Brian Allen. ' +
        "They're positive about our technical prowess but have concerns over long-term partnership and support. " +
        'Brian mentioned a past vendor who under-delivered in support. Essential to highlight our support accolades, ' +
        'testimonials, and specific case studies in the revised bid'
    },
    {
      title: 'Third',
      author: 'by John Doe',
      date: '26th August 2023',
      description:
        'Met with third and their CTO, Brian Allen. ' +
        "They're positive about our technical prowess but have concerns over long-term partnership and support. " +
        'Brian mentioned a past vendor who under-delivered in support. Essential to highlight our support accolades, ' +
        'testimonials, and specific case studies in the revised bid'
    }
  ];

  chats: ChatData[] = [
    {
      title: 'What is the evaluation criteria of this bid?',
      author: 'by John Doe',
      date: '15th August 2023'
    },
    {
      title: 'Can I get a summary of all activities related to the bid?',
      author: 'by John Doe',
      date: '26th August 2023'
    },
    {
      title: 'What was updated in the Technical Requirements Specification on 12th August 2023?',
      author: 'by John Doe',
      date: '26th August 2023'
    },
    {
      title: 'Hi!',
      author: 'by John Doe',
      date: '26th August 2023'
    }
  ];

  published = false;
  selectedOption = 'Activity';
  showPopup = false;
  title = 'Tender offer detail';
  navRoute = 'offers';
  noteContent = '';
  actualNote: NotesData = { title: '', author: '', date: '', description: '' };
  offer: TenderOffers = new TenderOffers();
  client: Client = new Client();

  constructor(
    public snackBar: MatSnackBar,
    public tenderOffersService: TenderOffersService,
    private route: ActivatedRoute,
    public clientService: ClientService
  ) {}

  public getActivities(): Observable<any> {
    return of({
      items: this.activities,
      total: this.activities.length
    });
  }

  ngOnInit(): void {
    const offerId = this.route.snapshot.params['id'];
    this.tenderOffersService.getOfferById(offerId).subscribe((actualOffer) => {
      this.offer = actualOffer;
      this.clientService.getClientById(this.offer.clientId).subscribe((client) => {
        this.client = client;
      });

      if (this.offer.status == 'published') {
        this.published = true;
      }
    });
  }

  openPopUp(note: NotesData): void {
    this.showPopup = true;
    this.actualNote = note;

    if (note.description) {
      this.noteContent = note.description;
    }
  }

  discardChanges(): void {
    this.showPopup = false;
  }

  saveNote(): void {
    this.showPopup = false;
    this.actualNote.description = this.noteContent;
  }

  deleteDocument(doc: DocData): void {
    this.docs = this.docs.filter((docs) => docs.id !== doc.id);
  }

  downloadDocument(): void {
    this.snackBar.open('Downloading document...', '', { duration: 3000 });
  }

  protected readonly faPlus = faPlus;
  protected readonly faTrashCan = faTrashCan;
  protected readonly faArrowDown = faArrowDown;
  protected readonly faFile = faFile;
  protected readonly faBolt = faBolt;
  protected readonly faSquareCheck = faSquareCheck;
  protected readonly faAngleRight = faAngleRight;
  protected readonly faComment = faComment;
}
