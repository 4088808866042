import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApplicationStages } from '../../../interfaces/application.interface';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { Offer } from '../../../models/offer';
import { UserInputService } from '../../../services/user-input.service';

@Component({
  selector: 'app-tender-offer-details',
  templateUrl: './tender-offer-details.component.html',
  styleUrls: ['./tender-offer-details.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TenderOfferDetailsComponent implements OnChanges {
  loading = false;

  description: FormControl;

  @Output() nextStep = new EventEmitter<number>();

  @Output() userInput = new EventEmitter<string>();

  @Input() clientId!: number;

  @Input() offer!: Offer;

  constructor(private userInputService: UserInputService) {
    this.description = new FormControl<string>('');

    this.description.valueChanges.subscribe((value) => {
      this.offer.description = value;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['offer']) {
      this.description.setValue(this.offer.description ? this.offer.description : '');
    }
  }

  endStep(): void {
    this.userInputService.updateData(this.description.value); // send user input to service
    this.nextStep.emit(ApplicationStages.CONTENT);
  }

  back(): void {
    this.nextStep.emit(ApplicationStages.FORM);
  }
}
