import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { User } from '../../models/user';
import { userRolesList } from '../../interfaces/user.interface';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class EditUserModalComponent {
  userDataForm: FormGroup;
  roleList = userRolesList;

  constructor(
    private dialogRef: MatDialogRef<EditUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: User
  ) {
    this.userDataForm = new FormGroup({
      name: new FormControl<string | undefined>(data?.name),
      lastName: new FormControl<string | undefined>(data?.lastName),
      role: new FormControl<string | undefined>(data?.role),
      position: new FormControl<string | undefined>(data?.position)
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
