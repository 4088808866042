import { Chat, ChatList } from '../models/chat';
import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  /**
   * API path
   */
  path = '/chat';

  /**
   * Constructor
   * @param http - HTTP client service
   * @param apiService
   */
  constructor(
    protected http: HttpClient,
    private apiService: ApiService
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  getChatsByOffer(offerId?: number): Observable<ChatList> {
    return this.http
      .get<IJsonObject>(`${this.path}/offer/${offerId}`)
      .pipe(map((chats) => Deserialize(chats, () => ChatList)));
  }

  getChatByUser(): Observable<ChatList> {
    return this.http.get<IJsonObject>(`${this.path}/general`).pipe(map((chats) => Deserialize(chats, () => ChatList)));
  }

  getChat(chatId: number): Observable<Chat> {
    return this.http.get<IJsonObject>(`${this.path}/${chatId}`).pipe(map((chat) => Deserialize(chat, () => Chat)));
  }

  createNewChat(offerId?: number): Observable<Chat> {
    const data: Chat = {
      title: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      offerId: offerId
    };

    return this.http
      .post<IJsonObject>(
        `${this.path}`,
        Serialize(data, () => Chat)
      )
      .pipe(map((chat) => Deserialize(chat, () => Chat)));
  }

  updateChat(newChat: Chat, chatId?: number): Observable<Chat> {
    return this.http
      .put<IJsonObject>(
        `${this.path}/${chatId}`,
        Serialize(newChat, () => Chat)
      )
      .pipe(map((chat) => Deserialize(chat, () => Chat)));
  }

  deleteChat(chatId: number): Observable<Chat> {
    return this.http.delete<IJsonObject>(`${this.path}/${chatId}`).pipe(map((chat) => Deserialize(chat, () => Chat)));
  }
}
