import { CUSTOM_ELEMENTS_SCHEMA, Component, OnDestroy } from '@angular/core';
import { ApplicationStages } from '../../interfaces/application.interface';
import { Client } from '../../models/client';
import { CustomSnackbarService } from '../../services/custom-snackbar.service';
import { NgIf } from '@angular/common';
import { Offer } from '../../models/offer';
import { StatusType } from '../../interfaces/offer.interface';
import { Subscription } from 'rxjs';
import { TenderOfferContentComponent } from './tender-offer-content/tender-offer-content.component';
import { TenderOfferDetailsComponent } from './tender-offer-details/tender-offer-details.component';
import { TenderOfferFormComponent } from './tender-offer-form/tender-offer-form.component';
import { TenderOffersService } from '../../services/tender-offers.service';

@Component({
  selector: 'app-generate-tender-offer',
  templateUrl: './generate-tender-offer.component.html',
  styleUrls: ['./generate-tender-offer.component.scss'],
  standalone: true,
  imports: [NgIf, TenderOfferFormComponent, TenderOfferDetailsComponent, TenderOfferContentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GenerateTenderOfferComponent implements OnDestroy {
  step: number;
  client!: number;
  offer!: Offer;

  offerSub: Subscription;

  titles = ['1. Application details and client', '2. Application description', '3. Application content'];

  title? = this.titles[0];

  constructor(
    private offerService: TenderOffersService,
    private snackbar: CustomSnackbarService
  ) {
    this.step = ApplicationStages.FORM;
    const offer: Offer = {
      status: StatusType.DRAFT
    };

    this.offerService.insertOffer(offer).subscribe((res) => {
      this.offer = res;
    });

    this.offerSub = this.offerService.save$.subscribe((res) => {
      if (res) {
        this.offerService.patchOffer(this.offer).subscribe(() => {
          this.snackbar.present('Saved application as a draft');
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.offerSub.unsubscribe();
  }

  changeStep(nextStep: number): void {
    this.step = nextStep;

    if (this.step === ApplicationStages.DETAILS || this.step === ApplicationStages.CONTENT) {
      this.title = this.titles[this.step];
      this.offerService.patchOffer(this.offer).subscribe();
    }
  }

  setClient(client: Client): void {
    this.offer.clientId = client.id;
    this.offer.clientName = client.name;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly ApplicationStages = ApplicationStages;
}
