import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

export class Section {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id: number;

  /**
   * Section title
   */
  @autoserializeAs(() => String) title: string;

  /**
   * Section body
   */
  @autoserializeAs(() => String) body: string;

  /**
   * Section order
   */
  @autoserializeAs(() => Number) order: number;

  /**
   * ID of the offer related with the section
   */
  @autoserializeAs(() => Number, 'offer_id') offerId: number;

  constructor(id: number, title: string, body: string, order: number, offerId: number) {
    this.id = id;
    this.title = title;
    this.body = body;
    this.order = order;
    this.offerId = offerId;
  }
}

export class SectionInput {
  /**
   * Section title
   */
  @autoserializeAs(() => String) title?: string;

  /**
   * Section body
   */
  @autoserializeAs(() => String) body?: string;

  /**
   * Section order
   */
  @autoserializeAs(() => Number) order?: number;

  /**
   * ID of the offer related with the section
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @autoserializeAs(() => Number, 'offer_id') offerId?: number;

  constructor(title?: string, body?: string, order?: number, offerId?: number) {
    this.title = title;
    this.body = body;
    this.order = order;
    this.offerId = offerId;
  }
}

export class SectionList {
  @autoserializeAsArray(() => Section) items: Section[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: Section[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}
