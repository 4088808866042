import { IJsonObject, autoserializeAs, autoserializeAsArray } from 'dcerialize';
import { StatusType } from '../interfaces/offer.interface';

export class Offer {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id?: number;

  /**
   * Status
   */
  @autoserializeAs(() => String) status?: StatusType;

  /**
   * Application name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * Description
   */
  @autoserializeAs(() => String) description?: string;

  /**
   * Client
   */
  @autoserializeAs(() => Number, 'client_id') clientId?: number;

  /**
   * Client name
   */
  @autoserializeAs(() => String, 'client_name') clientName?: string;

  /**
   * Uploaded by
   */
  @autoserializeAs(() => String, 'uploaded_by') uploadedBy?: string;

  /**
   * Project Number
   */
  @autoserializeAs(() => String, 'project_number') projectNumber?: string;

  static onSerialized(json: IJsonObject, instance: Offer): void {
    json['status'] = instance.status?.toUpperCase();
  }

  /**
   * Category
   *
   * @param id
   * @param status
   * @param name
   * @param description
   * @param clientId
   * @param uploadedBy
   * @param projectNumber
   */

  constructor(
    id?: number,
    status?: StatusType,
    name?: string,
    description?: string,
    clientId?: number,
    clientName?: string,
    uploadedBy?: string,
    projectNumber?: string
  ) {
    this.id = id;
    this.status = status;
    this.name = name;
    this.description = description;
    this.clientId = clientId;
    this.clientName = clientName;
    this.uploadedBy = uploadedBy;
    this.projectNumber = projectNumber;
  }
}

export class OfferList {
  @autoserializeAsArray(() => Offer) items: Offer[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: Offer[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}
