import { Client, ClientList } from '../models/client';
import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { QueryParamsInterface } from '@components/ng-dynamic-http-table';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  /**
   * API path
   */
  path = '/client';

  /**
   * Constructor
   * @param http - HTTP client service
   * @param apiService - API service
   */
  constructor(
    protected http: HttpClient,
    private apiService: ApiService
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  /**
   * Get all sections
   */
  list(): Observable<ClientList> {
    return this.http.get<IJsonObject>(`${this.path}`).pipe(map((clients) => Deserialize(clients, () => ClientList)));
  }

  listActive(apsParams?: QueryParamsInterface): Observable<ClientList> {
    if (apsParams) {
      const sortOptions: { [key: string]: string } = {
        /* eslint-disable @typescript-eslint/naming-convention */
        '+name': '+name',
        '-name': '-name'
        /* eslint-enable @typescript-eslint/naming-convention */
      };
      apsParams.sort = sortOptions[apsParams.sort ?? '+name'];
      const params = new HttpParams({
        fromObject: { ...apsParams }
      });

      return this.http
        .get<IJsonObject>(`${this.path}/active`, { params })
        .pipe(map((clients) => Deserialize(clients, () => ClientList)));
    } else {
      return this.http
        .get<IJsonObject>(`${this.path}/active`)
        .pipe(map((clients) => Deserialize(clients, () => ClientList)));
    }
  }

  getClientById(id?: number): Observable<Client> {
    return this.http.get<IJsonObject>(`${this.path}/${id}`).pipe(map((offer) => Deserialize(offer, () => Client)));
  }

  addClient(client: Client): Observable<Client> {
    return this.http
      .post<IJsonObject>(
        `${this.path}`,
        Serialize(client, () => Client)
      )
      .pipe(
        map((newClient) => Deserialize(newClient, () => Client)),
        catchError((error) => {
          throw error;
        })
      );
  }

  updateClient(client: Client): Observable<Client> {
    return this.http
      .put<IJsonObject>(
        `${this.path}/${client.id}`,
        Serialize(client, () => Client)
      )
      .pipe(
        map((updatedClient) => Deserialize(updatedClient, () => Client)),
        catchError((error) => {
          throw error;
        })
      );
  }

  deleteClient(client: Client): Observable<Client> {
    return this.http.delete<IJsonObject>(`${this.path}/${client.id}`).pipe(
      map((deletedClient) => Deserialize(deletedClient, () => Client)),
      catchError((error) => {
        throw error;
      })
    );
  }
}
