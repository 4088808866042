<div class="host">
  <section class="offer-container">
    <div class="header">
      <ion-icon class="step-icon" name="flash-outline"></ion-icon>
      <div class="column gap-sm">
        <span class="form-title">Generate new WBSO application</span>
        <h2 class="form-step no-margin">{{ title }}</h2>
      </div>
    </div>
    <div class="offer-background">
      <app-tender-offer-form
        class="fill"
        (selectedClient)="setClient($event)"
        [offer]="offer"
        (nextStep)="changeStep($event)"
        *ngIf="step === ApplicationStages.FORM"
      ></app-tender-offer-form>
      <app-tender-offer-details
        class="fill"
        [clientId]="client"
        [offer]="offer"
        (nextStep)="changeStep($event)"
        *ngIf="step === ApplicationStages.DETAILS"
      ></app-tender-offer-details>
      <app-tender-offer-content
        class="fill"
        [offer]="offer"
        (nextStep)="changeStep($event)"
        *ngIf="step === ApplicationStages.CONTENT"
      ></app-tender-offer-content>
    </div>
  </section>
</div>
