import { IJsonObject, autoserializeAs, autoserializeAsArray } from 'dcerialize';

export class Chat {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id?: number;

  /**
   * Title
   */
  @autoserializeAs(() => String) title?: string;

  /**
   * Offer ID
   */
  @autoserializeAs(() => Number, 'offer_id') offerId?: number;

  /**
   * Created at
   */
  @autoserializeAs(() => Date, 'created_at') createdAt?: Date;

  static onSerialized(json: IJsonObject, instance: Chat): void {
    json['created_at'] = instance.createdAt?.toISOString();
  }

  /**
   * Constructor
   * @param id
   * @param title
   * @param offerId
   */

  constructor(id = 0, title = '', offerId = 0) {
    this.id = id;
    this.title = title;
    this.offerId = offerId;
  }
}

export class ChatList {
  @autoserializeAsArray(() => Chat) items: Chat[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: Chat[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}
