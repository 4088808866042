import { IJsonObject, autoserializeAs, autoserializeAsArray } from 'dcerialize';
import { MessageRole } from '../interfaces/chat.interface';

export class Message {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id?: number;

  /**
   * Role
   */
  @autoserializeAs(() => String) role?: MessageRole;

  /**
   * Content
   */
  @autoserializeAs(() => String) content?: string;

  /**
   * Client ID
   */
  @autoserializeAs(() => Number, 'chat_id') chatId?: number;

  /**
   * Uploaded by
   */
  @autoserializeAs(() => String, 'uploaded_by') uploadedBy?: string;

  /**
   * Created at
   */
  @autoserializeAs(() => String, 'created_at') createdAt?: string;

  /**
   * Created at
   */
  @autoserializeAs(() => String, 'modified_at') modifiedAt?: string;

  static onSerialized(json: IJsonObject, instance: Message): void {
    json['role'] = instance.role?.toUpperCase();
  }

  constructor(
    id?: number,
    role?: MessageRole,
    chatId?: number,
    content?: string,
    uploadedBy?: string,
    createdAt?: string,
    modifiedAt?: string
  ) {
    this.id = id;
    this.role = role;
    this.chatId = chatId;
    this.content = content;
    this.uploadedBy = uploadedBy;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
  }
}

export class MessageList {
  @autoserializeAsArray(() => Message) items: Message[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: Message[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}
