import { ActivateAccountComponent } from '../shared-components/ng-login/activate-account/activate-account.component';
import { ActiveSessionGuard } from '../shared-components/ng-login/auth/active-session-guard.service';
import { AdminGuard } from '../services/admin.guard';
import { ApplicationsComponent } from './applications/applications.component';
import { AuthGuard } from '../shared-components/ng-login/auth/auth-guard.service';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { ClientsComponent } from './clients/clients.component';
import { CompanyComponent } from './company/company.component';
import { CompanyFeaturesInterface } from '../interfaces/company-features.interface';
import { EditOfferComponent } from './edit-offer/edit-offer.component';
import { FeaturesGuard } from '../services/features.guard';
import { ForgotPasswordComponent } from '../shared-components/ng-login/forgot-password/forgot-password.component';
import { GenerateTenderOfferComponent } from './generate-tender-offer/generate-tender-offer.component';
import { MainComponent } from './main/main.component';
import { NgLoginComponent } from '../shared-components/ng-login/login.component';
import { NgModule } from '@angular/core';
import { OverviewComponent } from './overview/overview.component';
import { RestorePasswordComponent } from '../shared-components/ng-login/restore-password/restore-password.component';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { TenderOffersDetailComponent } from './tender-offers-detail/tender-offers-detail.component';
import { UserManagementComponent } from './user-management/user-management.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [ActiveSessionGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: NgLoginComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset-password/:token',
        component: RestorePasswordComponent
      },
      {
        path: 'activate-account/:token',
        component: ActivateAccountComponent
      }
    ]
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full'
      },
      {
        path: 'overview',
        component: OverviewComponent,
        canActivate: [FeaturesGuard],
        data: {
          feature: CompanyFeaturesInterface.GrantGenerator,
          fallbackUrl: 'chat'
        }
      },
      {
        path: 'clients',
        component: ClientsComponent,
        canActivate: [FeaturesGuard],
        data: {
          feature: CompanyFeaturesInterface.GrantGenerator
        }
      },
      {
        path: 'new-tender',
        component: GenerateTenderOfferComponent,
        canActivate: [FeaturesGuard],
        data: {
          feature: CompanyFeaturesInterface.GrantGenerator
        }
      },
      {
        path: 'offers/:id',
        component: TenderOffersDetailComponent,
        canActivate: [FeaturesGuard],
        data: {
          feature: CompanyFeaturesInterface.GrantGenerator
        }
      },
      {
        path: 'chats/:id',
        component: ChatbotComponent,
        canActivate: [FeaturesGuard],
        data: {
          feature: CompanyFeaturesInterface.GrantGenerator
        }
      },
      {
        path: 'chat',
        component: ChatbotComponent,
        canActivate: [FeaturesGuard],
        data: {
          feature: CompanyFeaturesInterface.GeneralChat
        }
      },
      {
        path: 'applications',
        component: ApplicationsComponent,
        canActivate: [FeaturesGuard],
        data: {
          feature: CompanyFeaturesInterface.GrantGenerator
        }
      },
      {
        path: 'users',
        canActivate: [AdminGuard],
        component: UserManagementComponent
      },
      {
        path: 'application/:id',
        component: EditOfferComponent,
        canActivate: [FeaturesGuard],
        data: {
          feature: CompanyFeaturesInterface.GrantGenerator
        }
      },
      {
        path: 'company',
        canActivate: [AdminGuard],
        component: CompanyComponent,
        data: {}
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
