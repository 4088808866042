import { AuthService } from '../shared-components/ng-login/auth/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from '../interfaces/user.interface';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard {
  /**
   * Constructor
   * @param router
   * @param userService
   */
  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  /**
   * Whether the current user is admin or not
   * @returns {boolean}
   */
  canActivate(): boolean {
    if (AuthService.getUserData()?.role != UserRole.ADMIN) {
      this.router.navigate(['overview']);
    }

    return true;
  }
}
