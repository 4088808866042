import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Client } from '../../../models/client';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-client-details-modal',
  templateUrl: './client-details-modal.component.html',
  styleUrls: ['./client-details-modal.component.scss'],
  standalone: true,
  imports: [
    MatDividerModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatButtonModule
  ]
})
export class ClientDetailsModalComponent implements OnInit {
  client?: Client;
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Client,
    public dialogRef: MatDialogRef<ClientDetailsModalComponent>,
    private builder: NonNullableFormBuilder
  ) {
    this.form = this.builder.group({
      name: this.builder.control('', [Validators.required]),
      description: this.builder.control(''),
      sector: this.builder.control('', [Validators.required])
    });

    if (data) {
      this.client = data;
    }
  }

  ngOnInit(): void {
    if (this.client) {
      this.form.patchValue(this.client);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  saveClientDetails(): void {
    this.dialogRef.close(this.form.value);
  }
}
