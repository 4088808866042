import { autoserializeAs } from 'dcerialize';

export class GenerateUserInput {
  /**
   * User Input
   */
  @autoserializeAs(() => String, 'user_input') userInput: string;

  constructor(userInput: string) {
    this.userInput = userInput;
  }
}
