import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { BallLoaderComponent } from '../../../../shared-components/ball-loader/ball-loader.component';
import { MarkdownModule } from 'ngx-markdown';
import { Message } from '../../../../models/message';
import { MessageRole } from '../../../../interfaces/chat.interface';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MarkdownModule, BallLoaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MessageComponent {
  @Input() message!: Message;

  protected readonly messageRole = MessageRole;

  getMessageClass(): string {
    switch (this.message.role) {
      case MessageRole.Assistant:
        return 'message__bot';
      case MessageRole.User:
        return 'message__user';
      case MessageRole.System:
        return 'message__system';
      default:
        return '';
    }
  }
}
