import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { NgFor, NgIf } from '@angular/common';
import {
  addInputMatChipGrid,
  editInputMatChipGrid,
  pasteInputMatChipGrid,
  removeInputMatChipGrid
} from 'src/utils/mat-chip-grid-methods';
import { CustomSnackbarService } from '../../services/custom-snackbar.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserListInvitation } from '../../models/user';
import { UserService } from '../../services/user.service';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    NgFor,
    FontAwesomeModule,
    NgIf,
    MatInputModule,
    MatButtonModule
  ]
})
export class InviteUsersComponent {
  constructor(
    private dialogRef: MatDialogRef<InviteUsersComponent>,
    private builder: NonNullableFormBuilder,
    private userService: UserService,
    private snackbar: CustomSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: UserListInvitation
  ) {}

  protected readonly faXmark = faXmark;

  /**
   * Form to control the input email list
   */
  public form = this.builder.group({
    emailList: this.builder.array<string[]>([], Validators.required),
    message: this.builder.control<string>('')
  });

  closeModal(): void {
    this.dialogRef.close();
  }

  // This error handling is due to a bug related with MatError and MatChipGrid
  /**
   * Parameter to control if the form has been submitted
   */
  submittedFormWithErrors = false;

  /**
   * String to handle the form errors
   */
  errors = '';

  /**
   * Add the reused mat-chip-grid methods to component attributes in order to use then from the template
   */
  editEmail = editInputMatChipGrid;
  removeEmail = removeInputMatChipGrid;

  /**
   * Form control creation function for the reused mat-chip-grid methods
   * @param value User email
   * @returns A form control with the proper validator
   */
  inputCreation = (value: string): FormControl<string> => {
    return this.builder.control<string>(value, Validators.email);
  };

  /**
   * Mat-chip-grid separators
   */
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  /**
   * FormArray getter
   */
  get emailFormArray(): FormArray<FormControl<string>> {
    return this.form.controls.emailList;
  }

  sendInvitations(): void {
    this.submittedFormWithErrors = false;
    if (this.form.invalid) {
      this.submittedFormWithErrors = true;

      if (this.emailFormArray.hasError('required')) {
        this.errors = 'You must write at least one email';

        return;
      }

      if (this.form.invalid) {
        this.errors = 'One or more emails are invalid';

        return;
      }
    }

    const invitation = new UserListInvitation(this.emailFormArray.value, this.form.controls.message.value);
    this.userService.inviteUsers(invitation).subscribe(() => {
      this.closeModal();
    });
  }

  addEmail(event: MatChipInputEvent): void {
    if (event.value !== '') {
      addInputMatChipGrid(event, this.emailFormArray, this.inputCreation, this.snackbar);
    }
  }

  pasteEmailList(event: ClipboardEvent): void {
    pasteInputMatChipGrid(event, this.emailFormArray, this.inputCreation, this.snackbar);
  }
}
