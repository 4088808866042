import { AuthService } from '../../shared-components/ng-login/auth/auth.service';
import { CompanyInfoFormComponent } from './company-info-form/company-info-form.component';
import { Component } from '@angular/core';
import { UserRole } from '../../interfaces/user.interface';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  standalone: true,
  imports: [CompanyInfoFormComponent]
})
export class CompanyComponent {
  get isAdmin(): boolean {
    return AuthService.getUserData()?.role === UserRole.ADMIN;
  }
}
