export interface ChatInterface {
  id?: number;
  title?: string;
  offerId?: number;
}

export enum MessageRole {
  Assistant = 'ASSISTANT',
  User = 'USER',
  System = 'SYSTEM'
}
