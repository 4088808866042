import { BehaviorSubject, Subject } from 'rxjs/';
import { Deserialize, IJsonObject, Serialize, autoserializeAs } from 'dcerialize';
import { getStorageObject, removeStorageObject, setStorageObject } from '../../../utils/storage-manager';
import { ApiService } from 'src/services/api.service';
import { CredentialsInterface } from '../../../interfaces/user.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { User } from '../../../models/user';
import { UserService } from 'src/services/user.service';
import { map } from 'rxjs/operators';

/**
 * Login response structure
 */
export class LoginResponse {
  /**
   * If the login was successful or not
   */
  @autoserializeAs(() => Boolean, 'login_ok') loginOk: boolean | undefined;

  /**
   * Login access token
   */
  @autoserializeAs(() => String, 'access_token') accessToken: string | undefined;

  /**
   * If the user is blocked or not
   */
  @autoserializeAs(() => Boolean, 'blocked') blocked: boolean | undefined;
}

/**
 * Authentication service using JWT
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  /**
   * The Observable signaling that the user has logged out
   */
  public userLoggedOut: Subject<boolean> = new Subject<boolean>();

  /**
   * Url to redirect after login
   */
  public redirectUrl: string | null = null;

  /**
   * The Observable signaling that the user data has been loaded
   */
  userDataReady: Subject<User> = new Subject<User>();

  /**
   * path to the API
   */
  path = '/auth';

  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private userService: UserService
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  /**
   * This method stores the accessToken retrieved from the backend in the local storage
   * @param credentials - An object with the keys 'username' and 'password'
   * @returns The data object with the accessToken or an error
   */
  public login(credentials: CredentialsInterface): Observable<boolean> {
    const loginOkSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    const newCredentials = {
      email: credentials.email,
      password: credentials.password
    };

    this.http
      .post<IJsonObject>(`${this.path}/cookie`, newCredentials)
      .pipe(map((response) => Deserialize(response, () => LoginResponse)))
      .subscribe(
        (data) => {
          this.successLoginHandler(loginOkSubject, data);
          this.redirectUrl = '/overview';
        },
        (_error) => {
          // eslint-disable-next-line no-console
          console.log('Error en login :', _error);
          loginOkSubject.next(false);
        }
      );

    return loginOkSubject.asObservable();
  }

  /**
   * Handler for the login successful event after logging in
   * @param loginOkSubject
   * @param {LoginResponse} data Login response, with data about login or not
   */
  successLoginHandler(loginOkSubject: BehaviorSubject<boolean>, data: LoginResponse): void {
    if (data.loginOk) {
      this.fillUserData().subscribe(() => {
        this.userDataReady.next(AuthService.getUserData());
        loginOkSubject.next(true);
      });
    } else {
      loginOkSubject.complete();
    }
  }

  fillUserData(): Observable<User> {
    const dataLoaded = new Subject<User>();
    this.userService.profile().subscribe((user: User) => {
      setStorageObject(
        'userData',
        Serialize(user, () => User),
        'session'
      );
      dataLoaded.next(user);
    });

    return dataLoaded.asObservable();
  }

  /**
   * Whether the user has a valid token or not
   */
  public loggedIn(): boolean {
    return !!getStorageObject('userData');
  }

  /**
   * Remove the token from local storage and redirects the user to login page
   */
  public logout(): void {
    removeStorageObject('userData');
    this.userLoggedOut.next(true);
  }

  /**
   * Retrieves the user data from the JWT
   *
   * @return {Object} User data
   */
  static getUserData(): User | undefined {
    const user: IJsonObject = getStorageObject('userData');
    if (user) {
      return Deserialize(user, () => User);
    } else {
      return undefined;
    }
  }
}
