import { ActivatedRoute, RouterLink } from '@angular/router';
import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild } from '@angular/core';
import { MatButtonToggleGroup, MatButtonToggleModule } from '@angular/material/button-toggle';
import { ApplicationHistoryComponent } from './application-history/application-history.component';
import { ApplicationListComponent } from '../application-list/application-list.component';
import { ApplicationTabs } from '../../interfaces/application.interface';
import { CustomSnackbarService } from '../../services/custom-snackbar.service';
import { DocumentService } from '../../services/document.service';
import { ImportApplicationModalComponent } from '../import-application-modal/import-application-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  standalone: true,
  imports: [
    MatButtonToggleModule,
    NgIf,
    ApplicationListComponent,
    MatButtonModule,
    RouterLink,
    ApplicationHistoryComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ApplicationsComponent implements AfterViewInit {
  subtitleList = 'Manage and track your generations all in one place';
  subtitleHistory =
    'Catalog of past application files employed in model training; future applications ' +
    'will be generated using these files as a basis.';

  @ViewChild('selectedTab', { static: true }) selectedTab!: MatButtonToggleGroup;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private documentService: DocumentService,
    private snackbar: CustomSnackbarService
  ) {}

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.selectedTab.writeValue(parseInt(params['tab']));
      }
    });
  }

  importApplication(): void {
    const dialogRef = this.dialog.open(ImportApplicationModalComponent, { maxWidth: '40vw', minWidth: '300px' });

    dialogRef.afterClosed().subscribe((file: File) => {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        this.documentService.insertDocument(formData).subscribe(
          () => {
            this.snackbar.present('The document has been uploaded correctly');
            this.documentService.documentUploaded.next(true);
          },
          () => this.snackbar.present('The document upload had a failure.')
        );
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly ApplicationTabs = ApplicationTabs;
}
