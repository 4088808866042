import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { skip } from 'rxjs/operators';

/**
 * Selector, template-form and styles for login component
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ng-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, RouterLink],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

/**
 * Login component
 */
export class NgLoginComponent implements OnInit {
  /**
   * Login form
   */
  public loginForm: FormGroup;

  /**
   * Event emitter that will trigger an event when the login  is correct.
   */
  @Output() logged: EventEmitter<string | null> = new EventEmitter();

  /**
   * Component constructor
   */
  constructor(
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private router: Router
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  /**
   * Creates the login form fields and their validations
   */
  ngOnInit(): void {
    // If already logged go home
    if (this.authService.loggedIn()) {
      this.logged.emit(null);
    }
  }

  /**
   * Method called on the event 'onSubmit', if an access_token is received then navigates to home otherwise shows
   * the error
   */
  public login(): void {
    this.authService
      .login(this.loginForm.value)
      .pipe(skip(1)) // initialization
      .subscribe((loginOk) => {
        if (loginOk) {
          this.router.navigateByUrl('/overview');
        } else {
          this.snackBar.open('Wrong credentials', '', {
            duration: 3000
          });
        }
      });
  }
}
