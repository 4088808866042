import { Component, OnInit } from '@angular/core';
import { ConfirmModalInterface, ConfirmModalType } from '../../interfaces/confirm-modal.interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { expandWidthLeft, fadeIn, fadeOut } from '../../utils/animations';
import { map, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Chat } from '../../models/chat';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatService } from '../../services/chat.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ConversationComponent } from './conversation/conversation.component';
import { HttpClient } from '@angular/common/http';
import { Message } from '../../models/message';
import { MessageRole } from '../../interfaces/chat.interface';
import { MessageService } from '../../services/message.service';
import { NgIf } from '@angular/common';
import { TenderOffers } from '../../models/tender-offers';
import { TenderOffersService } from '../../services/tender-offers.service';
import { typeWriter } from '../../utils/display-texts';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  animations: [fadeIn, fadeOut, expandWidthLeft],
  standalone: true,
  imports: [NgIf, ChatListComponent, ConversationComponent]
})
export class ChatbotComponent implements OnInit {
  title = 'Tender offer detail';
  offer?: TenderOffers;

  chatList: Chat[] = [];
  chat!: Chat;
  messages: Message[] = [];

  source!: EventSource;

  botMessageBase: Message = new Message();

  showChats;
  hideChatList;

  private getOffer(offerId: number): void {
    this.offerService.getOfferById(offerId).subscribe((res) => {
      this.offer = res;
    });
  }

  private async writingSpeed(
    chunksLenght: number,
    processing: boolean,
    chunkProcessed: string,
    botMessage: Message
  ): Promise<void> {
    const thresholds = [
      { maxChunks: 10, delay: 500 },
      { maxChunks: 20, delay: 70 },
      { maxChunks: 30, delay: 50 },
      { maxChunks: 40, delay: 30 }
    ];

    let delay = 10;
    if (processing) {
      const matchingThreshold = thresholds.find((threshold) => chunksLenght < threshold.maxChunks);
      if (matchingThreshold) {
        delay = matchingThreshold.delay;
      }
    }

    await typeWriter(chunkProcessed, 0, delay, (substring: string) => {
      botMessage.content += substring;
    }).then();
  }

  constructor(
    private route: ActivatedRoute,
    private offerService: TenderOffersService,
    private chatService: ChatService,
    private messageService: MessageService,
    private dialog: MatDialog,
    protected http: HttpClient
  ) {
    this.botMessageBase.role = MessageRole.Assistant;
    this.botMessageBase.chatId = this.chat?.id;

    this.showChats = true;
    this.hideChatList = false;
  }

  ngOnInit(): void {
    if (this.route.snapshot.params['id']) {
      this.chatService.getChatsByOffer(this.offer?.id).subscribe((res) => {
        this.chatList = res.items;
        this.chat = res.items[res.total - 1];
      });
    } else {
      this.chatService.getChatByUser().subscribe((res) => {
        this.chatList = res.items;
        this.chat = res.items[res.total - 1];
        if (this.chat) {
          this.messageService.getMessagesFromChat(this.chat.id).subscribe((res) => {
            this.messages = res.items;
          });
        }
      });
    }
  }

  changeActiveChat(chat: Chat): void {
    if (chat != this.chat) {
      this.chat = chat;
      this.messageService.getMessagesFromChat(chat.id).subscribe((res) => {
        this.messages = res.items;
      });
    }
  }

  createNewChat(message?: Message): void {
    const createChat$ = this.chatService.createNewChat(this.offer?.id).pipe(
      switchMap((res: Chat) => {
        this.chat = res;
        this.chatList.push(res);

        return this.messageService.getMessagesFromChat(res.id);
      }),
      map((res) => {
        this.messages = res.items;
      })
    );

    if (message) {
      createChat$.subscribe(() => {
        message.chatId = this.chat.id;
        this.updateTitle(message.content, this.chat.id);
        this.sendMessage(message);
      });
    } else {
      createChat$.subscribe();
    }
  }

  deleteChat(chatId: number): void {
    const dialogData: ConfirmModalInterface = {
      title: 'Delete chat',
      message: 'Are you sure you want to delete this chat?',
      type: ConfirmModalType.DELETE
    };

    const dialogConfig: MatDialogConfig = {
      width: '400px',
      data: dialogData
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.chatService.deleteChat(chatId).subscribe(() => {
          this.chatList = this.chatList.filter((item) => item.id != chatId);
          this.chat = this.chatList[0];
          if (!this.chat) {
            this.messages = [];
          }
          this.messageService.getMessagesFromChat(this.chat?.id).subscribe((res) => {
            this.messages = res.items;
          });
        });
      }
    });
  }

  setShowChats(value: boolean): void {
    this.showChats = value;
    if (!this.showChats) {
      setTimeout(() => (this.hideChatList = !this.showChats), 300);
    } else {
      this.hideChatList = !this.showChats;
    }
  }

  updateTitle(value?: string, chatId?: number): void {
    this.chat.title = value;
    this.chatList.forEach((item) => {
      if (item.id == chatId) {
        item.title = value;
      }
    });

    this.chatService.updateChat(this.chat, chatId).subscribe();
  }

  sendMessage(message: Message): void {
    const chunks: string[] = [];
    let processing = false;
    let chatId: number;

    if (this.chat) {
      this.botMessageBase.content = '';
      this.botMessageBase.chatId = this.chat.id;
      let botMessage = Object.assign({}, this.botMessageBase);
      this.messageService
        .createMessage(message)
        .pipe(
          switchMap((res) => {
            this.messages.push(res);
            this.messages.push(botMessage);

            return this.messageService.getAnswer(this.chat.id);
          })
        )
        .subscribe(
          async (contentFormatted) => {
            chunks.push(contentFormatted);
            if (processing) {
              return;
            }
            processing = true;

            while (chunks.length > 0) {
              const chunkProcessed = chunks.shift();
              if (chunkProcessed) {
                await this.writingSpeed(chunks.length, processing, chunkProcessed, botMessage).then();
              }
            }
            const regex = /(\d+\*\d+\*)+/g;
            botMessage.content = botMessage.content?.replace(regex, (match: string): string => {
              return match.replace(/\*/g, '\\*');
            });
            this.messageService.createMessage(botMessage).subscribe();
            botMessage = Object.assign({}, this.botMessageBase);
            chatId = this.chat.id ? this.chat.id : 0;
          },
          () => {
            processing = false;
            if (!chatId && botMessage.content === '') {
              botMessage.role = MessageRole.System;
              botMessage.content =
                'Sorry, OpenAI is currently experiencing high traffic or is temporarily' +
                ' unavailable due to technical issues. Please try again later. We apologize for any inconvenience.';
              this.messageService.deleteMessage(this.messages[this.messages.length - 2].id).subscribe();
              botMessage = Object.assign({}, this.botMessageBase);
            }
          }
        );
    }
  }
}
