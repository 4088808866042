export interface ConfirmModalInterface {
  title: string;
  type: ConfirmModalType;
  message: string;
}

export enum ConfirmModalType {
  DELETE = 'delete',
  INFO = 'info',
  CONFIRM = 'confirm'
}
