<div class="auth-container column gap">
  <h2 class="auth-container-title horizontal-center no-margin">Activate account</h2>
  <form *ngIf="tokenValid; else notValidToken" class="form-container background-white" [formGroup]="form">
    <p>Welcome! Please complete your account activation by providing the details below:</p>

    <mat-label>First name</mat-label>
    <mat-form-field class="form-field">
      <input matInput id="username" formControlName="name" />
      <mat-error *ngIf="form.controls.name.hasError('required')"> Username is <strong>required</strong> </mat-error>
    </mat-form-field>

    <mat-label>Last name</mat-label>
    <mat-form-field class="form-field">
      <input matInput id="lastName" formControlName="lastName" />
    </mat-form-field>

    <mat-label>Password</mat-label>
    <mat-form-field class="form-field">
      <input matInput id="password" formControlName="password" type="password" />
      <mat-error *ngIf="form.get('password')?.hasError('required')"> Password is required </mat-error>
      <mat-error *ngIf="form.get('password')?.hasError('minlength')">
        Password must be at least {{ PASSWORD_MIN_LENGTH }} characters
      </mat-error>
      <mat-error *ngIf="!form.get('password')?.hasError('minlength') && form.get('password')?.hasError('pattern')">
        The password must contain at least one uppercase letter, one lowercase letter, one number and a special
        character
      </mat-error>
    </mat-form-field>

    <mat-label>Password confirmation</mat-label>
    <mat-form-field class="form-field">
      <input matInput id="confirmPassword" formControlName="confirmPassword" type="password" />
      <mat-error *ngIf="form.controls.confirmPassword.hasError('required')"> Confirm password is required </mat-error>
      <mat-error
        *ngIf="
          !form.controls.confirmPassword.hasError('required') && form.controls.confirmPassword.hasError('matching')
        "
      >
        Passwords do not match
      </mat-error>
    </mat-form-field>

    <div class="center row gap-sm">
      <button (click)="onSubmit()" class="btn btn--primary" [disabled]="!form.valid" mat-raised-button type="submit">
        Submit
      </button>
    </div>
  </form>

  <ng-template #notValidToken>
    <h3>The provided token is invalid or has expired</h3>
    <p>For security reasons the provided token is invalid or has expired.</p>
    <p>
      Please check the URL you received by mail or request to reset your password
      <a href="/forgot-password">clicking here</a>.
    </p>
  </ng-template>
</div>
