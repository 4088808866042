export enum ApplicationStages {
  FORM = 0,
  DETAILS = 1,
  CONTENT = 2
}

export enum ApplicationTabs {
  HISTORY = 0,
  GENERATED = 1
}
