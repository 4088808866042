import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AuthService } from '../../../shared-components/ng-login/auth/auth.service';
import { Chat } from '../../../models/chat';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { NgFor } from '@angular/common';
import { TenderOffers } from '../../../models/tender-offers';
import { slideInLeftNew } from '../../../utils/animations';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss', '../chatbot.component.scss'],
  animations: [slideInLeftNew],
  standalone: true,
  imports: [MatButtonModule, MatDividerModule, NgFor],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChatListComponent implements OnInit, OnChanges {
  @Input() chats: Chat[] = [];
  @Input() offer?: TenderOffers;
  @Input() activeChat?: Chat;
  @Input() showChats = true;

  @Output() chatSelected = new EventEmitter<Chat>();
  @Output() chatDeleted = new EventEmitter<number>();
  @Output() newChat = new EventEmitter<number>();
  @Output() hideChats = new EventEmitter<boolean>();

  username = '';

  ngOnInit(): void {
    const user = AuthService.getUserData();
    if (user) {
      if (user?.name) {
        this.username = user?.name;
        if (user?.lastName) {
          this.username += ' ' + user?.lastName;
        }
      }
    }

    // Just in case the user doesn't have name set
    if (this.username == '') {
      this.username = 'you';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showChats']) {
      this.showChats = changes['showChats'].currentValue;
    }
  }

  isNew(chat: Chat): boolean {
    const MAX_DIFFERENCE = 10; // 30 seconds

    if (chat.createdAt) {
      const diff = (new Date().getTime() - new Date(chat.createdAt).getTime()) / 1000;

      return diff - 3600 < MAX_DIFFERENCE;
    }

    return false;
  }

  changeActiveChat(chat: Chat): void {
    this.chatSelected.emit(chat);
  }

  createNewChat(): void {
    this.newChat.emit();
  }

  deleteChat(chatId?: number): void {
    this.chatDeleted.emit(chatId);
  }

  toogleShowChats(): void {
    this.hideChats.emit(!this.showChats);
  }
}
