<ng-container>
  <a
    class="navbar-item"
    [routerLink]="item.path"
    [class]="item.active ? 'navbar-item--active' : ''"
    routerLinkActive="active"
  >
    <ion-icon [name]="item.icon"></ion-icon>
    {{ item.name }}
  </a>
</ng-container>
