import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ActiveClientListComponent } from '../active-client-list/active-client-list.component';
import { ApplicationListComponent } from '../application-list/application-list.component';
import { ApplicationTabs } from '../../interfaces/application.interface';
import { Client } from '../../models/client';
import { ClientService } from '../../services/client.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, ApplicationListComponent, ActiveClientListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OverviewComponent {
  clients: Client[] = [];

  constructor(
    clientService: ClientService,
    private router: Router
  ) {
    clientService.listActive().subscribe((clients) => (this.clients = clients.items));
  }

  navigateToHistory(): void {
    this.router.navigate(['/applications'], { queryParams: { tab: ApplicationTabs.HISTORY } });
  }
}
