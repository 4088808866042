import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { globalImports, materialImports } from './app/app.module';
import { ActiveSessionGuard } from './shared-components/ng-login/auth/active-session-guard.service';
import { AppComponent } from './app/app.component';
import { AuthGuard } from './shared-components/ng-login/auth/auth-guard.service';
import { AuthInterceptor } from './shared-components/ng-login/auth/auth.interceptor';
import { AuthService } from './shared-components/ng-login/auth/auth.service';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(...materialImports, ...globalImports),
    AuthGuard,
    ActiveSessionGuard,
    AuthService,
    HttpClient,
    CapitalizePipe,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
