<div class="title-wrapper fill">
  <h2 class="no-margin">Application #{{ offer.projectNumber }}</h2>
  <span class="subtitle">Presented by: {{ offer.clientName }}</span>
</div>
<mat-divider class="fill"></mat-divider>
<app-sections-cdk-drop-list
  class="fill"
  [isTemplate]="false"
  *ngIf="offer?.id"
  [offerId]="offer.id"
  [edit]="edit"
  (disableButtons)="handleDisableButtons($event)"
></app-sections-cdk-drop-list>
<mat-divider class="fill"></mat-divider>
<div class="horizontal-end fill row gap-sm">
  <button *ngIf="!edit" mat-button class="btn btn--back" (click)="back()" [disabled]="disableButtons">
    <ion-icon name="chevron-back-outline"></ion-icon>
    Back
  </button>
  <button mat-button class="btn btn--primary" (click)="endStep()" [disabled]="disableButtons">
    {{ !edit ? 'Finish and download document' : 'Finish editing and download document' }}
    <ion-icon name="download-outline"></ion-icon>
  </button>
</div>
