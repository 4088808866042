<section class="column gap-sm">
  <h2 class="no-margin">Generated applications</h2>
  <span *ngIf="subtitle" class="description">{{ subtitle }}</span>
</section>

<ng-dynamic-http-table
  #applicationHistoryTable
  [displayedColumnsObject]="columns"
  [crudClass]="documentService"
  [crudMethodName]="'getDocuments'"
  [showSearch]="false"
  [mode]="'table'"
  [pageSize]="5"
  [useAPS]="true"
>
</ng-dynamic-http-table>
