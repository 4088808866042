import { CdkDrag, CdkDragPlaceholder, CdkDropList } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader, formatLanguage } from 'src/utils/translate';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DynamicHttpTableModule } from '@components/ng-dynamic-http-table';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MarkdownModule } from 'ngx-markdown';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { SecurityContext } from '@angular/core';

export const materialImports = [
  MatDividerModule,
  MatSelectModule,
  MatOptionModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatBadgeModule,
  MatButtonModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatButtonToggleModule,
  ReactiveFormsModule,
  FormsModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatSnackBarModule,
  ReactiveFormsModule,
  MatCheckboxModule,
  MatRadioModule,
  MatDialogModule,
  MatChipsModule,
  MatProgressSpinnerModule
];

export const globalImports = [
  BrowserModule,
  EditorModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  HttpClientModule,
  FontAwesomeModule,
  DynamicHttpTableModule,
  BrowserAnimationsModule,
  RouterModule,
  TranslateModule,
  CdkDropList,
  CdkDrag,
  CdkDragPlaceholder,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient]
    },
    defaultLanguage: formatLanguage(navigator.language)
  }),
  MarkdownModule.forRoot({ loader: HttpClient, sanitize: SecurityContext.NONE })
];
