<ng-container>
  <div class="column center gap">
    <ion-icon class="icon-lg error" name="alert-circle-outline"></ion-icon>
    <h2 class="center no-margin">
      {{ title }}
    </h2>
    <mat-dialog-content>
      <p>{{ message }}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="horizontal-center row">
        <button mat-raised-button class="btn" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button class="btn {{ getButtonStyle() }}" [mat-dialog-close]="true" cdkFocusInitial>
          {{ confirmButtonMessage }}
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</ng-container>
