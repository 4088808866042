<div class="message-container horizontal-center" [ngClass]="getMessageClass()">
  <div class="row message-content gap">
    <div *ngIf="this.message.role === messageRole.User" class="bot-icon-container">
      <ion-icon class="bot-icon" name="person-outline"></ion-icon>
    </div>
    <div *ngIf="this.message.role !== messageRole.User" class="bot-icon-container">
      <img class="bot-icon" src="assets/img/nucleoo-symbol.svg" alt="Nucleoo logo" />
    </div>
    <section>
      <markdown [data]="message.content"></markdown>
      <app-ball-loader *ngIf="this.message.content?.length === 0"></app-ball-loader>
    </section>
  </div>
</div>
