<form [formGroup]="loginForm" (ngSubmit)="login()">
  <div class="auth-container column gap">
    <span class="auth-container-title horizontal-center no-margin">Sign in</span>
    <div class="form-container">
      <div class="form-wrapper gap-sm">
        <mat-label>Email</mat-label>
        <mat-form-field>
          <input
            matInput
            class="fill"
            placeholder="{{ 'Email' }}"
            type="email"
            formControlName="email"
            name="email"
            required
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper gap-sm">
        <mat-label>Password</mat-label>
        <mat-form-field>
          <input
            class="fill"
            matInput
            placeholder="{{ 'Password' }}"
            type="password"
            formControlName="password"
            name="password"
            required
          />
        </mat-form-field>
      </div>
      <section class="actions vertical-center">
        <button type="submit" mat-button class="btn btn--lg btn--primary" [disabled]="loginForm.invalid">
          {{ 'Log in' }}
          <ion-icon name="log-in-outline"></ion-icon>
        </button>
        <a class="restore-password-link text-sm" routerLink="/forgot-password"> Forgot password? </a>
      </section>
    </div>
  </div>
</form>
