<h2 class="no-margin">Clients</h2>
<span class="text-sm text-light"
  >A centralized hub for all your client information. Keep details up-to-date for effective WBSO applications and better
  future generations.</span
>
<ng-dynamic-http-table
  class="table"
  #clientsTable
  [crudClass]="clientService"
  [crudMethodName]="'list'"
  [mode]="'table'"
  [pageSize]="5"
  [useAPS]="true"
  [displayedColumnsObject]="clientsColumns"
  [showSearch]="false"
  (actionPropagateHappened)="actionOnTableClicked($event)"
>
</ng-dynamic-http-table>

<div class="row">
  <button mat-button class="btn btn--primary btn--lg" (click)="openClientDetailsModal()">
    Add client
    <ion-icon name="add-outline"></ion-icon>
  </button>
</div>
