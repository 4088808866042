<div class="section-custom-placeholder" *cdkDragPlaceholder></div>
<div class="section column gap-sm">
  <div class="section-content row gap-sm">
    <ion-icon class="menu-icon icon-xl hidden" name="menu-outline"></ion-icon>
    <div id="content">
      <div [innerHTML]="section.body" class="section-content__text"></div>
    </div>
  </div>
  <div class="section-footer">
    <section class="row gap-sm">
      <!--      TO-DO: Delete ngIf and add implementation of regenerate-->
      <button mat-button class="btn btn--secondary" *ngIf="false">
        Regenerate
        <ion-icon name="color-wand-outline"></ion-icon>
      </button>
      <button (click)="changeEditMode()" mat-button class="btn btn--secondary">
        Edit
        <ion-icon name="pencil-outline"></ion-icon>
      </button>
    </section>
  </div>
</div>
