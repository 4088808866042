<section class="column gap-sm">
  <h2 class="no-margin">Import application</h2>
  <mat-divider></mat-divider>
</section>
<span>Application file</span>
<app-drag-drop [file]="file" (fileDropped)="fileDropped($event)"></app-drag-drop>
<span class="subtitle">The generator will utilise this document to generate new applications</span>
<section class="row gap horizontal-end">
  <button mat-button class="btn btn--secondary" (click)="closeModal()">Discard</button>
  <button mat-button class="btn btn--primary" [mat-dialog-close]="file">
    <ion-icon name="cloud-upload-outline"></ion-icon>
    Upload file
  </button>
</section>
