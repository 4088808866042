import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { CustomSnackbarService } from '../../../services/custom-snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, NgIf, MatButtonModule]
})
export class ForgotPasswordComponent {
  email: FormControl;
  constructor(
    private userService: UserService,
    private snackbarService: CustomSnackbarService
  ) {
    this.email = new FormControl<string>('', [Validators.required, Validators.email]);
  }

  onSubmit(): void {
    this.userService.restorePassword(this.email.value).subscribe(() => {
      this.snackbarService.present('Email sent');
    });
  }
}
