import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { Client } from '../../../models/client';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'app-active-client-item',
  templateUrl: './active-client-item.component.html',
  styleUrls: ['./active-client-item.component.scss'],
  standalone: true,
  imports: [MatChipsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ActiveClientItemComponent {
  @Input() client?: Client;
}
