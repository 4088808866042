import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { User, UserActivationAccountData, UserList, UserListInvitation, UserUpdate } from '../models/user';
import { catchError, map, tap } from 'rxjs/operators';
import { ActivateAccountInterface } from '../interfaces/user.interface';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs/';
import { CustomSnackbarService } from './custom-snackbar.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { QueryParamsInterface } from '@components/ng-dynamic-http-table';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * Path
   */
  path = '/user';

  private resetTokenValid: BehaviorSubject<string | null>;

  constructor(
    protected http: HttpClient,
    private snackbarService: CustomSnackbarService,
    private apiService: ApiService,
    private router: Router
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
    this.resetTokenValid = new BehaviorSubject<string | null>(null);
  }

  /**
   * CRUD profile method
   * @returns element retrieved profile from the CRUD service
   */
  profile(): Observable<User> {
    return this.http.get<IJsonObject>(`${this.path}/profile`).pipe(
      map((userData) => Deserialize(userData, () => User)),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  restorePassword(email: string): Observable<void> {
    return this.http.post<void>(`${this.path}/restore-password`, { email: email });
  }

  setPassword(password: string, token = ''): Observable<void> {
    return this.http.patch<void>(`${this.path}/set-password/${token}`, { password: password }).pipe(
      tap(() => {
        this.snackbarService.present('Password changed');
        this.router.navigate(['/login']);
      })
    );
  }

  validateResetPasswordToken(token: string): Observable<void> {
    return this.http.get<void>(`${this.path}/password/${token}`);
  }

  activateAccount(data: ActivateAccountInterface, token = ''): Observable<void> {
    return this.http.patch<void>(
      `${this.path}/activate-account/${token}`,
      Serialize(data, () => UserActivationAccountData)
    );
  }

  getUsers(apsParams: QueryParamsInterface): Observable<UserList> {
    const sortOptions: { [key: string]: string } = {
      /* eslint-disable @typescript-eslint/naming-convention */
      '+name': '+name|+last_name',
      '-name': '-name|-last_name',
      '+position': '+position',
      '-position': '-position',
      '+role': '+role',
      '-role': '-role'
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    apsParams.sort = sortOptions[apsParams.sort ?? '+role'];
    const params = new HttpParams({
      fromObject: { ...apsParams }
    });

    return this.http
      .get<IJsonObject>(`${this.path}`, { params })
      .pipe(map((users) => Deserialize(users, () => UserList)));
  }

  updateProfile(updatedUser: User): Observable<User> {
    return this.http
      .patch<IJsonObject>(
        `${this.path}/${updatedUser.id}`,
        Serialize(updatedUser, () => UserUpdate)
      )
      .pipe(map((user) => Deserialize(user, () => User)));
  }

  deleteUser(user: User): Observable<void> {
    return this.http
      .delete<void>(`${this.path}/${user.id}`)
      .pipe(catchError((err: HttpErrorResponse) => this.snackbarService.showError(err)));
  }

  /**
   * Invite users from the given list
   *
   * @param invitation - a list with the user emails
   */
  inviteUsers(invitation: UserListInvitation): Observable<void> {
    return this.http
      .post<void>(
        `${this.path}/invite-users`,
        Serialize(invitation, () => UserListInvitation)
      )
      .pipe(
        tap(() => {
          this.snackbarService.present('Invitations sent');
        }),
        catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
      );
  }
}
