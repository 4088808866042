import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
// eslint-disable-next-line max-len
import { TenderOfferContentComponent } from '../generate-tender-offer/tender-offer-content/tender-offer-content.component';

@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.scss'],
  standalone: true,
  imports: [TenderOfferContentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EditOfferComponent {}
