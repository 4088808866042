<h1 *ngIf="offer">{{ offer.description }}</h1>
<div class="chats-container">
  <app-chat-list
    *ngIf="!hideChatList"
    @fadeIn
    @fadeOut
    [chats]="chatList"
    [offer]="offer"
    [activeChat]="chat"
    [showChats]="showChats"
    (chatSelected)="changeActiveChat($event)"
    (hideChats)="setShowChats($event)"
    (newChat)="createNewChat()"
    (chatDeleted)="deleteChat($event)"
    class="list"
  ></app-chat-list>
  <app-conversation
    *ngIf="chat"
    [@expandWidthLeft]="showChats ? 'collapsed' : 'expanded'"
    [chat]="chat"
    [messages]="messages"
    [showChats]="showChats"
    [hideChatList]="hideChatList"
    (showChatList)="setShowChats($event)"
    (updateTitle)="updateTitle($event, chat.id)"
    (createChat)="createNewChat($event)"
    (sendMessage)="sendMessage($event)"
    class="conversation"
  ></app-conversation>
</div>
