import { autoserializeAs, autoserializeAsArray } from 'dcerialize';
import { StatusType } from '../interfaces/offer.interface';

export class TenderOffers {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id?: number;

  /**
   * Description
   */
  @autoserializeAs(() => String) description?: string;

  /**
   * Application name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * File Name
   */
  @autoserializeAs(() => String, 'file_name') fileName?: string;

  /**
   * Project number
   */
  @autoserializeAs(() => String, 'project_number') projectNumber?: string;

  /**
   * Budget
   */
  @autoserializeAs(() => Number) budget?: number;

  /**
   * Client name
   */
  @autoserializeAs(() => String, 'client_name') clientName?: string;

  /**
   * Client ID
   */
  @autoserializeAs(() => Number, 'client_id') clientId?: number;

  /**
   * Uploaded by
   */
  @autoserializeAs(() => String, 'uploaded_by') uploadedBy?: string;

  /**
   * Status
   */
  @autoserializeAs(() => String) status?: StatusType;

  /**
   * Deadline
   */
  @autoserializeAs(() => String) deadline?: string;

  /**
   * Created at
   *
   */
  @autoserializeAs(() => String, 'created_at') createdAt?: string;

  /**
   *
   * @param id
   * @param description
   * @param name
   * @param fileName
   * @param budget
   * @param uploadedBy
   * @param clientName
   * @param clientId
   * @param status
   */
  constructor(
    id?: number,
    description?: string,
    name?: string,
    fileName?: string,
    budget?: number,
    uploadedBy?: string,
    clientName?: string,
    clientId?: number,
    status?: StatusType
  ) {
    this.id = id;
    this.description = description;
    this.name = name;
    this.budget = budget;
    this.fileName = fileName;
    this.uploadedBy = uploadedBy;
    this.clientName = clientName;
    this.clientId = clientId;
    this.status = status;
  }
}

export class TenderOffersList {
  @autoserializeAsArray(() => TenderOffers) items: TenderOffers[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: TenderOffers[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}
