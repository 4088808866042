<ng-container>
  <div class="column gap-lg">
    <h3 class="">Invite users</h3>
    <form [formGroup]="form" (ngSubmit)="sendInvitations()">
      <div class="form-wrapper">
        <mat-label>Emails</mat-label>
        <mat-form-field>
          <mat-chip-grid #chipGrid formArrayName="emailList" aria-required="true">
            <mat-chip-row
              *ngFor="let valueControl of form.controls.emailList.controls; let emailIndex = index"
              [editable]="true"
              (edited)="editEmail($event, emailIndex, emailFormArray)"
              class="removable-chip"
            >
              {{ valueControl.value }}
              <button matChipRemove (click)="removeEmail(emailIndex, emailFormArray)">
                <fa-icon [icon]="faXmark"></fa-icon>
              </button>
            </mat-chip-row>
            <input
              placeholder="example@domain.com"
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addEmail($event)"
              (paste)="pasteEmailList($event)"
            />
          </mat-chip-grid>
        </mat-form-field>
        <mat-error *ngIf="submittedFormWithErrors">
          {{ errors }}
        </mat-error>
        <mat-label>Invitation message</mat-label>
        <mat-form-field class="invite-message">
          <textarea
            matInput
            rows="4"
            class="form__input"
            placeholder="Write a message:"
            formControlName="message"
          ></textarea>
        </mat-form-field>
      </div>
      <section class="actions vertical-center">
        <button type="button" mat-raised-button class="btn btn--accent" (click)="closeModal()">
          {{ 'Discard' }}
        </button>
        <button type="submit" mat-raised-button class="btn btn--primary">
          {{ 'Send' }}
        </button>
      </section>
    </form>
  </div>
</ng-container>
