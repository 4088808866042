import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { NavbarItemInterface, navbarItems } from '../../interfaces/navbar.interface';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { navbarHighlight, rotate180 } from '../../utils/animations';
import { AuthService } from '../../shared-components/ng-login/auth/auth.service';
import { Company } from '../../models/company';
import { CompanyService } from '../../services/company.service';
import { FALLBACK_LOGO_URL } from '../../utils/constants';
import { LogoService } from '../../services/logo.service';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavbarItemComponent } from './navbar-item/navbar-item.component';
import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TenderOffersService } from '../../services/tender-offers.service';
import { User } from '../../models/user';
import { UserRole } from '../../interfaces/user.interface';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [rotate180, navbarHighlight],
  standalone: true,
  imports: [
    NgIf,
    MatToolbarModule,
    RouterLink,
    NgFor,
    NavbarItemComponent,
    MatButtonModule,
    RouterLinkActive,
    MatMenuModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  fullNavbar: boolean | undefined;
  isLoggedIn: boolean | undefined;
  isAdmin: boolean | undefined;

  navbarItems: NavbarItemInterface[] = [];

  fullNavbarPaths = ['/new-tender'];

  canGoBack = false;
  hasGrantGeneratorFeature = false;
  menuOpen = false;

  title = '';
  companyLogo: string | undefined;

  logoSubscription?: Subscription;

  user?: User = AuthService.getUserData();

  @Input() canBeDraft = false;

  @ViewChild(MatMenuTrigger) menu?: MatMenuTrigger;

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private router: Router,
    private logoService: LogoService,
    private offerService: TenderOffersService
  ) {
    this.fullNavbar = false;
    this.companyService.getCompany().subscribe((company: Company) => {
      setTimeout(() => {
        this.companyLogo = company.logoUrl ? this.companyService.getLogo() : FALLBACK_LOGO_URL;
      }, 0);
      this.isAdmin = AuthService.getUserData()?.role == UserRole.ADMIN;
      navbarItems.forEach((navbarItem) => {
        if (!navbarItem.permissionRequired || company[navbarItem.permissionRequired]) {
          if ((navbarItem.needsAdmin && this.isAdmin) || !navbarItem.needsAdmin) {
            this.navbarItems.push(navbarItem);
          }
        }
      });

      this.hasGrantGeneratorFeature = company.grantGeneratorFeature || false;
      this.title = this.hasGrantGeneratorFeature ? 'Grant Generator' : 'Private Chat';
      this.setIfFullNavbar(this.title);
    });

    this.logoSubscription = this.logoService.data$.subscribe((data) => {
      this.companyLogo = data;
    });
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.loggedIn();
    this.title = this.hasGrantGeneratorFeature ? 'Grant Generator' : 'Private Chat';

    this.canGoBack = this.router.url.includes('forgot-password');
    this.setIfFullNavbar(this.title);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setIfFullNavbar(this.title);
        this.canGoBack = this.router.url.includes('forgot-password');
      }
    });
  }

  ngAfterViewInit(): void {
    this.menu?.menuOpened.subscribe(() => {
      this.toggleMenu();
    });
    this.menu?.menuClosed.subscribe(() => {
      this.toggleMenu();
    });
  }

  ngOnDestroy(): void {
    this.logoSubscription?.unsubscribe();
  }

  public logout(): void {
    this.authService.logout();
    this.isLoggedIn = false;
    this.router.navigateByUrl('/login');
  }

  public toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  setIfFullNavbar(defaultTitle: string): void {
    this.canBeDraft = false;
    this.fullNavbarPaths.forEach((path) => {
      this.fullNavbar = this.router.url.includes(path);
    });

    if (this.router.url.includes('/new-tender')) {
      this.title = 'Generate new WBSO application';
      this.canBeDraft = true;
    } else {
      this.title = defaultTitle;
    }
  }

  goBack(): void {
    window.history.back();
  }

  saveAsDraft(): void {
    this.offerService.saveAsDraft();
  }

  goToSettings(): void {
    this.router.navigateByUrl('company');
  }
}
