<div class="menu">
  <h2>Nucleoo AI</h2>
  <h1>WBSO application generator</h1>
  <div class="row center gap-lg">
    <button mat-button class="btn btn--primary gap" routerLink="/new-tender">
      Create application <ion-icon name="add-outline"></ion-icon>
    </button>
    <button mat-button (click)="navigateToHistory()" class="btn btn--secondary gap">
      Import application <ion-icon name="cloud-upload-outline"></ion-icon>
    </button>
  </div>
</div>
<div class="offers-wrapper">
  <app-application-list></app-application-list>
</div>
<section>
  <app-active-client-list [clients]="clients"></app-active-client-list>
</section>
