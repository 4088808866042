import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild } from '@angular/core';
import { ConfirmModalInterface, ConfirmModalType } from '../../interfaces/confirm-modal.interface';
import {
  DisplayedColumnsObject,
  DynamicHttpTableComponent,
  DynamicHttpTableModule
} from '@components/ng-dynamic-http-table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Client } from 'src/models/client';
import { ClientDetailsModalComponent } from './client-details-modal/client-details-modal.component';
import { ClientService } from 'src/services/client.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { CustomSnackbarService } from '../../services/custom-snackbar.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  standalone: true,
  imports: [DynamicHttpTableModule, MatButtonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ClientsComponent {
  clientsColumns: DisplayedColumnsObject = {
    name: {
      label: 'Client',
      main: true,
      template:
        '<div class="row vertical-center gap-sm">' +
        '<ion-icon class="table-icon" name="briefcase-outline"></ion-icon>' +
        '${name}' +
        '</div>'
    },
    offersQuantity: {
      label: 'Applications',
      sortable: false,
      filter: (offersQuantity: number) => (offersQuantity || 0).toString()
    },
    active: {
      label: 'Status',
      sortable: false,
      extraClassFn: (client: Client) => {
        return this.getActiveClass(client.active);
      },
      filter: (active: boolean) => (active ? 'Active' : '')
    },
    menu: {
      label: '',
      type: 'menu-button',
      class: 'fa-solid fa-ellipsis-vertical',
      elements: [
        {
          label: 'Remove',
          class: 'fa-solid fa-trash warn-color-600',
          itemClass: 'warn-color-600',
          method: (el: Client): void => this.deleteClient(el)
        }
      ]
    }
  };

  @ViewChild('clientsTable') clientsTable!: DynamicHttpTableComponent;

  getActiveClass(active?: boolean): string {
    const className = 'chip ';

    return className + `chip__${active ? 'active' : 'inactive'}`;
  }

  constructor(
    public clientService: ClientService,
    private matDialog: MatDialog,
    private snackbarService: CustomSnackbarService
  ) {}

  public actionOnTableClicked(_event: { event: string; element: Client }): void {
    if (_event.event === 'row_click') {
      this.openClientDetailsModal(_event.element);
    }
  }

  openClientDetailsModal(client?: Client): void {
    const newClientModal: MatDialogConfig = {
      minWidth: '50rem'
    };

    if (client) {
      // Attach client to the modal config
      newClientModal.data = client;
    }

    const dialogRef = this.matDialog.open(ClientDetailsModalComponent, newClientModal);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (client) {
          this.clientService.updateClient({ ...client, ...result }).subscribe(() => {
            this.clientsTable.getResults();
            this.snackbarService.present('Client updated successfully');
          });
        } else {
          this.clientService.addClient(result).subscribe(() => {
            this.clientsTable.getResults();
            this.snackbarService.present('Client updated successfully');
          });
        }
      }
    });
  }

  deleteClient(client: Client): void {
    const dialogData: ConfirmModalInterface = {
      title: 'Delete client',
      message: `Are you sure you want to delete the client ${client.name}?`,
      type: ConfirmModalType.DELETE
    };

    const dialogConfig = {
      minWidth: '400px',
      data: dialogData
    };

    const dialogRef = this.matDialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      (confirm) => {
        if (confirm) {
          this.clientService.deleteClient(client).subscribe(() => {
            this.clientsTable.getResults();
            this.snackbarService.present('Client deleted successfully');
          });
        }
      },
      () => {
        this.snackbarService.present('Error deleting client');
      }
    );
  }
}
