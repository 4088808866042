export interface StatusInterface {
  status: StatusType;
}

export enum StatusType {
  PUBLISHED = 'published',
  INPROGRESS = 'in-progress',
  DRAFT = 'draft',
  COMPLETED = 'completed'
}
