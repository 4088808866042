import { ActivatedRoute, Router } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApplicationStages } from '../../../interfaces/application.interface';
import { CustomSnackbarService } from '../../../services/custom-snackbar.service';
import { Document } from '../../../models/document';
import { DocumentService } from '../../../services/document.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { NgIf } from '@angular/common';
import { Offer } from '../../../models/offer';
import { SectionService } from '../../../services/section.service';
import { SectionsCdkDropListComponent } from '../../sections-cdk-drop-list/sections-cdk-drop-list.component';
import { StatusType } from '../../../interfaces/offer.interface';
import { TenderOffersService } from '../../../services/tender-offers.service';
import { TinymceEditorComponent } from '../../edit-section/tinymce-editor/tinymce-editor.component';

@Component({
  selector: 'app-tender-offer-content',
  templateUrl: './tender-offer-content.component.html',
  styleUrls: ['./tender-offer-content.component.scss'],
  standalone: true,
  imports: [MatDividerModule, NgIf, SectionsCdkDropListComponent, MatButtonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TenderOfferContentComponent {
  content = '';
  @Input() offer: Offer = new Offer();
  @Input() edit = false;
  @Output() nextStep = new EventEmitter<number>();
  @ViewChild(TinymceEditorComponent) tinymceEditorComponent!: TinymceEditorComponent;
  disableButtons = false;

  constructor(
    private router: Router,
    private offerService: TenderOffersService,
    private documentService: DocumentService,
    private sectionService: SectionService,
    private snackbar: CustomSnackbarService,
    private route: ActivatedRoute
  ) {
    if (this.route.snapshot.params.id) {
      this.offerService.getOfferById(this.route.snapshot.params.id).subscribe((res) => {
        this.offer = res;
      });
    }
  }
  endStep(): void {
    this.offerService.exportToDocx(this.offer.id).subscribe(
      (document: Document) => {
        window.open(this.documentService.downloadDocument(document.id), '_blank');
        this.snackbar.present('Document saved correctly');
      },
      () => {
        this.snackbar.present('Error when saving the document');
      }
    );
    this.offer.status = StatusType.PUBLISHED;
    this.offerService.updateOffer(this.offer).subscribe();
    this.router.navigateByUrl('/overview');
  }

  back(): void {
    this.sectionService.getSectionsByOffer(this.offer.id).subscribe((sections) => {
      sections.items.forEach((section) => {
        this.sectionService.delete(section.id).subscribe();
      });
    });
    this.nextStep.emit(ApplicationStages.DETAILS);
  }

  handleDisableButtons(disabled: boolean): void {
    this.disableButtons = disabled;
  }
}
