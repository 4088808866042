import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, OnInit } from '@angular/core';
import { ConfirmModalInterface, ConfirmModalType } from '../../interfaces/confirm-modal.interface';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConfirmModalComponent implements OnInit {
  title = '';

  message = '';

  type = ConfirmModalType.CONFIRM;

  confirmButtonMessage = 'Accept';

  constructor(
    private dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalInterface
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title;
      this.message = this.data.message;
      this.type = this.data.type;
      this.setConfirmButtonMessage(this.type);
    }
  }

  setConfirmButtonMessage(type: ConfirmModalType): void {
    switch (type) {
      case ConfirmModalType.DELETE:
        this.confirmButtonMessage = 'Delete';
        break;
      case ConfirmModalType.INFO:
        this.confirmButtonMessage = 'Ok';
        break;
      case ConfirmModalType.CONFIRM:
        this.confirmButtonMessage = 'Accept';
        break;
      default:
        this.confirmButtonMessage = 'Accept';
    }
  }

  getButtonStyle(): string {
    switch (this.type) {
      case ConfirmModalType.DELETE:
        return 'btn--destructive';
      case ConfirmModalType.INFO:
        return 'btn--primary';
      case ConfirmModalType.CONFIRM:
        return 'btn--primary';
      default:
        return 'btn--primary';
    }
  }
}
