import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { Chat } from '../../../models/chat';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Message } from '../../../models/message';
import { MessageComponent } from './message/message.component';
import { MessageRole } from '../../../interfaces/chat.interface';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss', '../chatbot.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [NgIf, MatButtonModule, NgFor, MessageComponent, MatInputModule, ReactiveFormsModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConversationComponent implements OnInit, OnChanges, AfterViewInit {
  private userMessageBase: Message = new Message();

  protected readonly messageRole = MessageRole;

  @Input() chat?: Chat;
  @Input() showChats = true;
  @Input() hideChatList = false;
  @Input() messages: Message[] = [];

  @Output() updateTitle = new EventEmitter<string>();
  @Output() createChat = new EventEmitter<Message>();
  @Output() sendMessage = new EventEmitter<Message>();
  @Output() showChatList = new EventEmitter<boolean>();

  @ViewChild('conversationInput') conversationInput!: ElementRef<HTMLTextAreaElement>;

  value = '';

  textAreaHeight = 30;

  maxHeight = 120;

  constructor(private renderer: Renderer2) {
    this.userMessageBase.role = MessageRole.User;
  }

  adjustTextareaSize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';

    if (textarea.scrollHeight > this.maxHeight) {
      textarea.style.overflow = 'auto';
      this.textAreaHeight = this.maxHeight;
    } else {
      this.textAreaHeight = textarea.scrollHeight;
    }
  }

  ngOnInit(): void {
    this.forceChatBottomScroll();
  }

  ngAfterViewInit(): void {
    const style = getComputedStyle(this.conversationInput.nativeElement);
    this.maxHeight = parseInt(style.maxHeight);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chat']) {
      this.renderer.selectRootElement(this.conversationInput?.nativeElement).focus({ preventScroll: true });
      this.forceChatBottomScroll();
    }
  }

  forceChatBottomScroll(): void {
    const targetNode = document.getElementsByClassName('chat-content')[0];
    const config = { childList: true };

    const callback: MutationCallback = function (mutationsList: MutationRecord[], _observer: MutationObserver) {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          targetNode.scrollTo(0, targetNode.scrollHeight);
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }

  send(): void {
    this.userMessageBase.content = this.value;
    this.userMessageBase.chatId = this.chat?.id;
    this.userMessageBase.createdAt = new Date().toString();
    const message = Object.assign({}, this.userMessageBase);
    if (!this.chat) {
      this.createChat.emit(message);
    } else {
      this.sendMessage.emit(message);
    }
    if (this.messages.length == 0) {
      this.updateTitle.emit(this.value);
    }
    this.value = '';
  }

  onKeyDown(event: KeyboardEvent): void {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      this.send();
    }

    if (event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      const textarea = event.target as HTMLTextAreaElement;
      this.value += '\n';
      textarea.selectionStart = textarea.selectionEnd = textarea.value.length;
      this.adjustTextareaSize(event);
    }
  }

  toogleShowChats(): void {
    this.showChatList.emit(!this.showChats);
  }
}
