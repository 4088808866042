<h1>User management</h1>
<div class="row vertical-center horizontal-space-between gap-lg">
  <h3>Users</h3>
  <button mat-button (click)="openInviteUsers()" class="btn btn--primary">
    Invite
    <fa-icon [icon]="faUserPlus"></fa-icon>
  </button>
</div>
<div class="table-filter-wrapper">
  <ng-dynamic-http-table
    class="table"
    [hidden]="loading || !tableRows"
    #userTable
    [crudClass]="userService"
    [crudMethodName]="'getUsers'"
    [displayedColumnsObject]="userColumns"
    [mode]="'table'"
    [useAPS]="true"
    [pageSize]="5"
    [showSearch]="false"
    (dynamicTableLoadHappened)="initializeFilters()"
    (dataReceivedHappened)="tableRows = userTable.resultsLength !== 0"
    (actionPropagateHappened)="actionPropagatedHappened($event)"
    [pageSizeOptions]="[]"
    (loadingResults)="toggleLoading($event)"
  >
  </ng-dynamic-http-table>
</div>
