import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmModalInterface, ConfirmModalType } from '../../interfaces/confirm-modal.interface';
import {
  DisplayedColumnsObject,
  DynamicHttpTableComponent,
  DynamicHttpTableModule,
  SqlOperators
} from '@components/ng-dynamic-http-table';
import { faCloudArrowUp, faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { CustomSnackbarService } from '../../services/custom-snackbar.service';
import { EditUserModalComponent } from '../edit-user-modal/edit-user-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InviteUsersComponent } from '../invite-users/invite-users.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { displayTextOrDefaultString } from '../../utils/display-texts';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  standalone: true,
  imports: [MatButtonModule, FontAwesomeModule, DynamicHttpTableModule]
})
export class UserManagementComponent implements OnInit {
  userColumns!: DisplayedColumnsObject;
  loading = true;
  editUserInfoData!: User;

  protected readonly faCloudArrowUp = faCloudArrowUp;
  protected readonly faPlus = faPlus;
  protected readonly faUserPlus = faUserPlus;

  /**
   * Child table component
   */
  @ViewChild('userTable', { static: false }) userTable?: DynamicHttpTableComponent;

  /**
   * Whether we have rows to show in the table or not
   */
  tableRows: boolean | undefined;

  public isOpenModal = false;

  constructor(
    public userService: UserService,
    public dialog: MatDialog,
    public snackbarService: CustomSnackbarService
  ) {}

  private updateFields(data: User) {
    this.editUserInfoData.name = data.name;
    this.editUserInfoData.lastName = data.lastName;
    this.editUserInfoData.role = data.role;
    this.editUserInfoData.position = data.position;
  }

  ngOnInit(): void {
    this.userColumns = {
      name: {
        label: 'Name',
        type: 'text',
        template: '${name} ${lastName}',
        filter: displayTextOrDefaultString,
        sortable: true,
        searchableInAPS: { field: 'user.name', operator: SqlOperators.Contains, value: null }
      },
      lastName: {
        show: false,
        filter: displayTextOrDefaultString,
        searchableInAPS: { field: 'user.last_name', operator: SqlOperators.Contains, value: null }
      },
      email: {
        filter: displayTextOrDefaultString,
        searchableInAPS: { field: 'user.email', operator: SqlOperators.Contains, value: null }
      },
      position: {
        label: 'Position',
        type: 'text',
        sortable: true,
        searchableInAPS: { field: 'user.position', operator: SqlOperators.Contains, value: null }
      },
      role: {
        label: 'Role',
        type: 'text',
        sortable: true,
        filter: (el) => displayTextOrDefaultString(el, 'user'),
        searchableInAPS: { field: 'user.role', operator: SqlOperators.Contains, value: null }
      },
      menu: {
        label: '',
        type: 'menu-button',
        class: 'fa-solid fa-ellipsis-vertical',
        elements: [
          {
            label: 'Remove',
            class: 'fa-solid fa-trash warn-color-600',
            itemClass: 'warn-color-600',
            method: (el: User): void => this.deleteUser(el)
          }
        ]
      }
    };
  }

  deleteUser(user: User): void {
    const dialogData: ConfirmModalInterface = {
      title: 'Delete user',
      message: `Are you sure you want to delete the user ${user.name} ${user.lastName}?`,
      type: ConfirmModalType.DELETE
    };

    const dialogConfig = {
      minWidth: '400px',
      data: dialogData
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.userService.deleteUser(user).subscribe(
          () => {
            this.snackbarService.open('User successfully deleted', '', { duration: 2500 });
            this.userTable?.getResults();
          },
          () => {
            this.snackbarService.open('Error when deleting the user', '', { duration: 2500 });
          }
        );
      }
    });
  }

  editUserProfile(user: User): void {
    const dialogConfig = {
      width: '400px',
      data: user
    };
    const dialogRef = this.dialog.open(EditUserModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.updateFields(data);
        this.userService.updateProfile(this.editUserInfoData).subscribe(
          () => {
            this.snackbarService.open('User successfully edited', '', { duration: 2500 });
          },
          () => {
            this.snackbarService.open('Error when editing the user', '', { duration: 2500 });
          }
        );
      }
    });
  }

  openInviteUsers(): void {
    this.dialog.open(InviteUsersComponent, { width: '400px' });
  }

  initializeFilters(): void {
    if (this.userTable) {
      this.userTable.filters = [];
    }
  }

  toggleLoading(loading: boolean): void {
    this.loading = loading;
  }

  actionPropagatedHappened(_event: { event: string; element: User }): void {
    if (_event.event === 'row_click') {
      this.editUserProfile(_event.element);
    }
  }
}
