import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, ViewChild } from '@angular/core';
import { ConfirmModalInterface, ConfirmModalType } from '../../interfaces/confirm-modal.interface';
import {
  DisplayedColumnsObject,
  DynamicHttpTableComponent,
  DynamicHttpTableModule
} from '@components/ng-dynamic-http-table';
import { Router, RouterLink } from '@angular/router';
import { CapitalizePipe } from '../../pipes/capitalize.pipe';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { CustomSnackbarService } from '../../services/custom-snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { Offer } from '../../models/offer';
import { StatusType } from '../../interfaces/offer.interface';
import { TenderOffers } from '../../models/tender-offers';
import { TenderOffersService } from '../../services/tender-offers.service';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, DynamicHttpTableModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ApplicationListComponent {
  @Input() subtitle?: string;

  @Input() link = true;

  @Input() pageSize = 5;

  @ViewChild('applicationsTable', { static: false }) applicationsTable?: DynamicHttpTableComponent;

  columns: DisplayedColumnsObject;
  constructor(
    public tenderOffersService: TenderOffersService,
    private router: Router,
    private capitalizePipe: CapitalizePipe,
    private dialog: MatDialog,
    private snackbar: CustomSnackbarService
  ) {
    this.columns = {
      name: {
        label: 'Application name',
        type: 'text',
        template:
          '<div class="row gap-sm vertical-center">' +
          '<ion-icon class="table-icon" name="flash-outline"></ion-icon>' +
          '${name}' +
          '</div>'
      },
      projectNumber: {
        label: 'ID',
        type: 'text'
      },
      clientName: {
        label: 'Client name',
        type: 'text'
      },
      status: {
        label: 'Status',
        extraClassFn: (offer: Offer) => {
          return this.getStatusClass(offer.status);
        },
        filter: (status: StatusType) => this.capitalizePipe.transform(status)
      },
      menu: {
        label: '',
        type: 'menu-button',
        class: 'fa-solid fa-ellipsis-vertical',
        elements: [
          {
            label: 'Remove',
            class: 'fa-solid fa-trash warn-color-600',
            itemClass: 'warn-color-600',
            method: (el: Offer): void => this.deleteTenderOffer(el)
          }
        ],
        sortable: false
      }
    };
  }

  getStatusClass(status?: StatusType): string {
    const className = 'chip ';
    switch (status) {
      case StatusType.DRAFT:
        return className + `chip__${StatusType.DRAFT}`;
      case StatusType.PUBLISHED:
        return className + `chip__${StatusType.PUBLISHED}`;
      default:
        return className;
    }
  }

  deleteTenderOffer(offer: Offer): void {
    const dialogData: ConfirmModalInterface = {
      title: 'Delete application',
      message: 'Are you sure you want to delete this application?',
      type: ConfirmModalType.DELETE
    };

    const dialogConfig = {
      data: dialogData,
      minWidth: '400px'
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.tenderOffersService.deleteOffer(offer).subscribe(
          () => {
            this.snackbar.present('The application has been deleted successfully');
            this.applicationsTable?.getResults();
          },
          () => this.snackbar.present('There has been an error deleting the application')
        );
      }
    });
  }

  handleActionHappened(offer: { event: string; element: Offer }): void {
    if (offer.event == 'row_click') {
      this.redirectToOfferDetail(offer.element);
    }
  }

  redirectToOfferDetail(offer: TenderOffers): void {
    this.router.navigate([`/application/${offer.id}`]);
  }
}
