/**
 * Interface that represents the user credentials
 */
export interface CredentialsInterface {
  email: string;
  password: string;
}

export interface ActivateAccountInterface {
  password: string;
  lastName?: string;
  name?: string;
}

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user'
}

export const userRolesList: UserRole[] = [UserRole.ADMIN, UserRole.USER];
