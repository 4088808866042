<div class="background column gap-lg">
  <div class="column gap-sm">
    <section class="horizontal-space-between vertical-center">
      <span class="text-lg vertical-center row gap-sm">
        <ion-icon class="accent-color-600" name="briefcase-outline"></ion-icon>
        {{ client?.name }}
      </span>
      <mat-chip [disableRipple]="true">
        {{ client?.sector }}
      </mat-chip>
    </section>

    <section class="description">
      {{ client?.description }}
    </section>
  </div>

  <section class="applications">
    <ion-icon name="document-text"></ion-icon>
    {{ client?.clientOffers ? client?.clientOffers?.length : 0 }} applications
  </section>
</div>
