import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavbarItemInterface } from '../../../interfaces/navbar.interface';

@Component({
  selector: 'app-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.scss'],
  standalone: true,
  imports: [RouterLinkActive, RouterLink],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NavbarItemComponent {
  @Input() item!: NavbarItemInterface;
}
