import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomSnackbarService } from '../../services/custom-snackbar.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrls: ['./drag-drop.component.scss'],
  standalone: true,
  imports: [NgIf],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DragDropComponent {
  @Output() fileDropped = new EventEmitter<File>();

  @Input() file?: File;

  // Error message for when the file typ is not correct
  @Input() errorMessage = 'Only .pdf and .docx files are allowed';

  @Input() formats = 'PDF or DOCX';

  constructor(private snackbar: CustomSnackbarService) {}

  preventDefault(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragEnter(event: DragEvent): void {
    this.preventDefault(event);
    const item = event.currentTarget as HTMLElement;
    if (item) {
      item?.classList.add('drag-drop-area__hovering');
    }
  }

  onDragLeave(event: DragEvent): void {
    this.preventDefault(event);
    const item = event.currentTarget as HTMLElement;
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (item && !item.contains(relatedTarget)) {
      item?.classList.remove('drag-drop-area__hovering');
    }
  }

  handleDrop(event: DragEvent): void {
    const fileList = event.dataTransfer?.items;

    this.preventDefault(event);

    const item = event.currentTarget as HTMLElement;
    const relatedTarget = event.relatedTarget as HTMLElement;
    const file = fileList?.[0].getAsFile();

    if (item && !item.contains(relatedTarget)) {
      item?.classList.remove('drag-drop-area__hovering');
    }

    if (this.checkFileType(file)) {
      this.file = file ? file : undefined;
      this.fileDropped.emit(this.file);
    } else {
      this.snackbar.present(this.errorMessage);
    }
  }

  checkFileType(file?: File | null): boolean {
    return (
      !!file &&
      (file.type === 'application/pdf' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type.startsWith('image/'))
    );
  }

  handleFileBrowser(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (this.checkFileType(file)) {
      this.file = file;
      this.fileDropped.emit(this.file);
    } else {
      this.snackbar.present(this.errorMessage);
    }
  }
}
