<div class="company-info">
  <form [formGroup]="form">
    <div class="form-wrapper">
      <h3>Company information</h3>
      <p class="subtitle">These fields will be used to generate the "About the company" part of each document</p>
      <mat-divider></mat-divider>
      <section class="column gap-sm">
        <span>Company name</span>
        <mat-form-field class="form-input">
          <input matInput placeholder="Company Name" formControlName="name" />
        </mat-form-field>
      </section>
      <section class="column gap-sm">
        <span>Address Street</span>
        <mat-form-field>
          <input matInput formControlName="address" />
          <mat-error *ngIf="form.controls.address.hasError('required')"> This field is required </mat-error>
        </mat-form-field>
      </section>
      <section class="column gap-sm">
        <span>Tax Id</span>
        <mat-form-field>
          <input matInput formControlName="taxId" />
          <mat-error *ngIf="form.controls.taxId.hasError('required')"> This field is required </mat-error>
        </mat-form-field>
      </section>
    </div>
    <div class="form-wrapper">
      <h3>Contact information</h3>
      <p class="subtitle">Used to attach your contact information in your documents generations</p>
      <mat-divider></mat-divider>
      <section class="column gap-sm">
        <span>Contact Person</span>
        <mat-form-field>
          <input matInput formControlName="contactPerson" />
          <mat-error *ngIf="form.controls.contactPerson.hasError('required')"> This field is required </mat-error>
        </mat-form-field>
      </section>
      <section class="column gap-sm">
        <span>Phone number</span>
        <mat-form-field>
          <input matInput formControlName="contactPhone" />
          <mat-error *ngIf="form.controls.contactPhone.hasError('required')"> This field is required </mat-error>
        </mat-form-field>
      </section>
      <section class="column gap-sm">
        <span>Email</span>
        <mat-form-field>
          <input matInput formControlName="contactEmail" />
          <mat-error *ngIf="form.controls.contactEmail.hasError('required')"> This field is required </mat-error>
          <mat-error
            *ngIf="!form.controls.contactEmail.hasError('required') && form.controls.contactEmail.hasError('email')"
          >
            This field must be a valid email
          </mat-error>
        </mat-form-field>
      </section>
    </div>
    <div class="horizontal-end">
      <button mat-button (click)="submit()" class="btn btn--primary">Save changes</button>
    </div>
  </form>
  <mat-divider vertical></mat-divider>
  <div class="form-wrapper">
    <h3>Company logo</h3>
    <p class="subtitle">Upload your company logo to be used in your documents</p>
    <div>
      <section class="column gap-sm">
        <app-drag-drop
          [file]="logo"
          (fileDropped)="saveLogo($event)"
          errorMessage="Only image files are allowed"
          formats="PNG or JPG"
        ></app-drag-drop>
      </section>
      <section *ngIf="previewLogo" class="center column subtitle">
        <span>This is the logo that will be uploaded</span>
        <img class="preview-logo" [src]="previewLogo" />
      </section>
    </div>
    <div class="center">
      <button [disabled]="!previewLogo" mat-button (click)="submitLogo()" class="btn btn--primary">
        <fa-icon [icon]="faCloudUpload"></fa-icon>
        Upload logo
      </button>
    </div>
  </div>
</div>
